import { Button } from "@heroui/react";
import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";
import PinField from "react-pin-field";
import {
  authRepository,
  FontAwesomeSvgIcon,
  showErrorToast,
  Timer,
  useAsyncAction,
} from "../../../core/core";
import { useEffect, useMemo, useRef, useState } from "react";
import AuthWrapper from "./components/AuthWrapper";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { showRegisterError } from "./components/showRegisterError";
import { AnalyticsEvents, logAnalyticsEvent } from "../../firebase/firebase";

type OtpVerificationState = {
  code: string;
  token: string;
};

export default function OtpVerification() {
  const navigate = useNavigate();
  const { phone, token } = useParams();

  const tokenExpiration = useMemo(
    () => new Date(JSON.parse(atob(token!.split(".")[1]!)).exp * 1000),
    [token]
  );

  const [state, setState] = useState<OtpVerificationState>({
    code: "",
    token: token!,
  });

  const pinCodeFieldRef = useRef<HTMLInputElement[]>(null);

  const [submitState, submitAction] = useAsyncAction({
    action: (params: OtpVerificationState) =>
      authRepository.verify(
        params.token,
        params.code,
        localStorage.getItem("invite_code") ?? null
      ),
    onFailure: (failure) =>
      failure.fold({
        onNetworkFailure: () => showErrorToast(failure),
        onServerFailure: (serverFailure) => {
          if (serverFailure.statusCode == 400) {
            if (serverFailure.errorCode === "MAX_OTP_TRIES") {
              toast.error(
                "شما بیش از حد مجاز کد اشتباه وارد کرده‌اید. لطفاً یک کد جدید درخواست کنید"
              );
            }
            return;
          } else if (serverFailure.statusCode == 401) {
            toast.warning(
              'کد وارد شده منقضی شده است. لطفاً گزینه "دریافت مجدد کد" را انتخاب کنید.'
            );
            return;
          }

          showErrorToast(failure);
        },
      }),
    onSuccess: () => {
      logAnalyticsEvent("login", { method: "phone" });

      const redirectTo = new URL(location.href).searchParams.get("redirect_to");
      navigate(redirectTo ?? "/");
      localStorage.removeItem("invite_code");
    },
  });

  const [resendState, resendCodeAction] = useAsyncAction({
    action: (phone: string) => authRepository.register(phone),
    onFailure: (failure) =>
      failure.fold({
        onNetworkFailure: () => showErrorToast(failure),
        onServerFailure: (failure) =>
          showRegisterError(failure.errorCode ?? ""),
      }),
    onSuccess: (token) => {
      navigate(`/auth/phone/verification/${phone}/${token}` + location.search, {
        replace: true,
      });

      setState({ code: "", token });

      pinCodeFieldRef.current?.forEach((e) => (e.value = ""));
      pinCodeFieldRef.current![0].focus();

      toast.success("کد تایید مجدد ارسال شد.");
    },
  });

  const [codeState, setCodeState] = useState<"idle" | "valid" | "invalid">(
    "idle"
  );
  useEffect(() => {
    if (state.code.length !== 5) {
      setCodeState("idle");
    }

    submitState.result?.fold({
      onSuccess: () => setCodeState("valid"),
      onFailure: (failure) =>
        setCodeState(
          failure.fold({
            onNetworkFailure: () => "idle",
            onServerFailure: (serverFailure) =>
              serverFailure.errorCode == "WRONG_OTP_CODE" ? "invalid" : "idle",
          })
        ),
    });
  }, [state.code.length, submitState.result]);

  return (
    <AuthWrapper>
      <div className="w-full md:w-80 left mt-8">
        <p className="text-center mb-4">
          کد ارسال شده به شماره {"0" + phone?.substring(2)} را وارد نمایید.
        </p>
        <div
          className="flex flex-row justify-center"
          style={{ direction: "ltr" }}
        >
          <PinField
            ref={pinCodeFieldRef}
            autoFocus
            className={
              "pin-field " +
              (codeState === "valid"
                ? " complete"
                : codeState === "invalid"
                ? " invalid"
                : "")
            }
            length={5}
            validate={/^[0-9]$/}
            disabled={submitState.isLoading}
            inputMode="numeric"
            onChange={(code) => {
              const newState = { ...state, code };
              setState(newState);

              if (code.length == 5) {
                logAnalyticsEvent(AnalyticsEvents.otp.submitButtonClicked);
                submitAction.invoke(newState);
              }
            }}
          />
        </div>
        {codeState === "invalid" ? (
          <p className="text-tiny text-danger mt-2 text-center">
            کد وارد شده صحیح نیست!
          </p>
        ) : (
          <></>
        )}
        <div className="flex flex-row justify-center mt-4 mb-4">
          <Button
            className="max-w-80"
            color="primary"
            variant="solid"
            size="lg"
            fullWidth
            endContent={<FontAwesomeSvgIcon icon={faArrowRightToBracket} />}
            isDisabled={state.code.length != 5}
            isLoading={submitState.isLoading}
            onPress={() => {
              logAnalyticsEvent(AnalyticsEvents.otp.submitButtonClicked);
              submitAction.invoke(state);
            }}
          >
            ورود
          </Button>
        </div>
        <div className="flex flex-row justify-center text-tiny gap-1">
          <Timer
            end={tokenExpiration}
            renderTime={(time) => `${time} مانده تا دریافت مجدد کد`}
            endComponent={
              <Button
                className="max-w-80"
                variant="light"
                color="primary"
                size="sm"
                fullWidth
                isDisabled={submitState.isLoading}
                isLoading={resendState.isLoading}
                onPress={() => {
                  logAnalyticsEvent(
                    AnalyticsEvents.otp.resendCodeButtonClicked
                  );
                  resendCodeAction.invoke(phone);
                }}
              >
                دریافت مجدد کد
              </Button>
            }
          />
        </div>
      </div>
    </AuthWrapper>
  );
}
