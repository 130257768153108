import { Spinner, Tabs, Tab } from "@heroui/react";
import {
  FailureView,
  paymentRepository,
  useAsyncValue,
  useStore,
} from "../../../../core/core";
import { useCallback, useEffect, useMemo } from "react";
import { SubscriptionPlanCard } from "./components/SubscriptionPlanCard";
import { AnalyticsEvents, logAnalyticsEvent } from "../../../firebase/firebase";

export default function Subscriptions() {
  const [state, asyncValueController] = useAsyncValue({
    getData: useCallback(() => paymentRepository.getSubscriptions(), []),
  });

  useEffect(() => {
    asyncValueController.invoke(null);
  }, [asyncValueController]);

  const weeklyPlans = useMemo(
    () =>
      state.maybeFold({
        orElse: () => [],
        loadSuccess: (plans) =>
          plans
            .filter((plan) => plan.duration === 7)
            .sort(
              (a, b) =>
                a.features.batteryMultiplier.value -
                b.features.batteryMultiplier.value
            ),
      }),
    [state]
  );

  const monthlyPlans = useMemo(
    () =>
      state.maybeFold({
        orElse: () => [],
        loadSuccess: (plans) =>
          plans
            .filter((plan) => plan.duration === 30)
            .sort(
              (a, b) =>
                a.features.batteryMultiplier.value -
                b.features.batteryMultiplier.value
            ),
      }),
    [state]
  );

  const activeSubscription = useStore((state) => state.activeSubscription);
  const getActiveSubscription = useStore(
    (state) => state.getActiveSubscription
  );
  useEffect(() => {
    getActiveSubscription();
  }, [getActiveSubscription]);

  return (
    <>
      {state.maybeFold({
        orElse: () => <Spinner />,
        loadFailure: (failure) => (
          <FailureView
            failure={failure}
            onRetry={() => asyncValueController.invoke(null)}
          />
        ),
        loadSuccess: () =>
          activeSubscription.maybeFold({
            orElse: () => <Spinner />,
            loadFailure: (failure) => (
              <FailureView failure={failure} onRetry={getActiveSubscription} />
            ),
            loadSuccess: (activeSubscription) => (
              <>
                <Tabs
                  fullWidth
                  variant="solid"
                  color="primary"
                  defaultSelectedKey={"monthly"}
                  onSelectionChange={(tab) =>
                    logAnalyticsEvent(AnalyticsEvents.paymentPlans.tabClicked, {
                      tab,
                    })
                  }
                >
                  <Tab key="weekly" title="هفتگی">
                    <div className="flex flex-col md:flex-row-reverse gap-4">
                      {weeklyPlans.map((plan) => (
                        <SubscriptionPlanCard
                          plan={plan}
                          key={plan.id}
                          hasActiveSubscription={activeSubscription.id != null}
                        />
                      ))}
                    </div>
                  </Tab>
                  <Tab key="monthly" title="ماهانه">
                    <div className="flex flex-col md:flex-row-reverse gap-4">
                      {monthlyPlans.map((plan) => (
                        <SubscriptionPlanCard
                          plan={plan}
                          key={plan.id}
                          hasActiveSubscription={activeSubscription.id != null}
                        />
                      ))}
                    </div>
                  </Tab>
                </Tabs>
              </>
            ),
          }),
      })}
    </>
  );
}
