import { initializeApp } from "firebase/app";
import {
  Analytics,
  getAnalytics,
  logEvent,
  setAnalyticsCollectionEnabled,
  setUserId,
} from "firebase/analytics";
import {
  getMessaging,
  getToken,
  Messaging,
  onMessage,
} from "firebase/messaging";
import { getPerformance } from "firebase/performance";
import { EnvironmentVariables } from "../../../core/core";

const firebaseConfig = EnvironmentVariables.isProduction
  ? {
      apiKey: "AIzaSyDeG3qfofMUagWL--RPb7LosGUwrA8P3zY",
      authDomain: "fandogh-prod.firebaseapp.com",
      projectId: "fandogh-prod",
      storageBucket: "fandogh-prod.firebasestorage.app",
      messagingSenderId: "720450138057",
      appId: "1:720450138057:web:7254f1c781de1b9d420218",
      measurementId: "G-XLXR7MSD2N",
    }
  : {
      apiKey: "AIzaSyAoJhOSh5YzpYg_7UuND0ozjZAEUtKGPTU",
      authDomain: "fandogh-chat.firebaseapp.com",
      projectId: "fandogh-chat",
      storageBucket: "fandogh-chat.firebasestorage.app",
      messagingSenderId: "303905728086",
      appId: "1:303905728086:web:5742ee49cf17f47e833df7",
      measurementId: "G-Y8JJ36QCSH",
    };

export let messaging: Messaging;
let analytics: Analytics;

export function getFcmToken(): Promise<string> {
  return getToken(messaging, {
    vapidKey: EnvironmentVariables.isProduction
      ? "BDQI2e-NW3Th6wPENvdXyLM4VVjabX2iB0-7wR2AFNWlozD-OEpqgPgNw70tXJ6aud1mVQPdjZ783ZHunvYu2Vk"
      : "BETokazttQryHYpuDzlvNQi3UycwkahF-qYT9ahCP36-gGVZHoYqQFm0nmlsjOZ-a_BSWWbs-oiyL5JXfO76Meo",
  });
}

try {
  const app = initializeApp(firebaseConfig);
  analytics = getAnalytics(app);
  setAnalyticsCollectionEnabled(analytics, true);
  messaging = getMessaging(app);
  getPerformance(app);

  const isSafari =
    navigator.userAgent.includes("Safari") &&
    !navigator.userAgent.includes("Chrome") &&
    !navigator.userAgent.includes("Chromium");

  // Don't show foreground notifications on Safari; After 2 - 3 notifications are
  // shown the token is changed spontaneously and it fully stops working until
  // the app is reloaded and a new token is generated.
  if (!isSafari && Notification.permission === "granted") {
    // TODO: Use navigator.serviceWorker.ready when main service worker is set up
    navigator.serviceWorker
      .getRegistrations()
      .then((serviceWorkers) => {
        onMessage(messaging, (message) => {
          console.log("[fcm]", message);

          // Don't show notification if user is in the related chat page
          if (
            message.data?.deeplink &&
            message.data.deeplink.endsWith(window.location.pathname)
          ) {
            return;
          }

          serviceWorkers[0]?.showNotification(
            message.notification?.title ?? "",
            {
              body: message.notification?.body,
              icon: message.notification?.icon,
              data: message.data,
              silent: false,
            }
          );
        });
      })
      .catch((e) => console.error("[fcm]", e));
  }
} catch (error) {
  console.error("Firebase initialization failed.", error);
}

export function logAnalyticsEvent(
  name: string,
  params?: Record<string, string | number>
) {
  try {
    logEvent(analytics, name, params);

    // Log events in debug mode
    if (!EnvironmentVariables.isProduction) {
      console.log("[analytics]", name, params);
    }
  } catch (error) {
    console.error("logAnalyticsEvent failed.", error);
  }
}

export function setAnalyticsUserId(userId: string | null) {
  try {
    setUserId(analytics, userId);
  } catch (error) {
    console.error("setAnalyticsUserId failed.", error);
  }
}
