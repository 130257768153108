import { LocalDataSource } from "../../../core/core";

export class FirebaseLocaleDataSource extends LocalDataSource {
  private _fcmTokenKey = "fcm_token";
  private _notificationPermissionLastRequestedAtKey =
    "notification_permission_last_requested_at";

  setFcmToken(fcmToken: string): void {
    this.cacheManager.set(this._fcmTokenKey, fcmToken);
  }

  getFcmToken(): string | undefined {
    return this.cacheManager.get<string>(this._fcmTokenKey);
  }

  removeFcmToken(): void {
    this.cacheManager.remove(this._fcmTokenKey);
  }

  setNotificationPermissionLastRequestedAt(date: Date): void {
    this.cacheManager.set(
      this._notificationPermissionLastRequestedAtKey,
      date.toISOString()
    );
  }

  getNotificationPermissionLastRequestedAt(): Date | null {
    const dateString = this.cacheManager.get<string>(
      this._notificationPermissionLastRequestedAtKey
    );

    try {
      return dateString ? new Date(dateString) : null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  removeNotificationPermissionLastRequestedAt(): void {
    this.cacheManager.remove(this._notificationPermissionLastRequestedAtKey);
  }
}
