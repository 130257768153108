import { HttpClient, SocketClient } from "./core";
import { createHttpClient } from "./data/core/injectable-modules";
import { AuthRepository } from "../features/auth/auth";
import AuthRemoteDataSource from "../features/auth/data/auth-remote-data-source";
import AuthLocaleDataSource from "../features/auth/data/auth-local-data-source";
import { CacheManager } from "./data/core/cache-manager";
import ProfileRemoteDataSource from "../features/profile/data/profile-remote-data-source";
import ProfileRepository from "../features/profile/data/profile-repository";
import {
  ChatRepository,
  ChatSocketDataSource,
  ChatRemoteDataSource,
} from "../features/chat/domain/domain";
import {
  WalletRemoteDataSource,
  WalletRepository,
} from "../features/wallet/wallet";
import {
  MatchmakingRemoteDataSource,
  MatchmakingRepository,
  MatchmakingSocketDataSource,
} from "../features/match_making/match-making";
import {
  PaymentRemoteDataSource,
  PaymentRepository,
} from "../features/payment/domain/domain";
import {
  PromoCodeRemoteDataSource,
  PromoCodeRepository,
} from "../features/promo-code/domain/domain";
import {
  ReferralRepository,
  ReferralRemoteDataSource,
} from "../features/referral/domain/domain";
import {
  FirebaseLocaleDataSource,
  FirebaseRemoteDataSource,
  FirebaseRepository,
} from "../features/firebase/domain/domain";
import {
  FeedbackRemoteDataSource,
  FeedbackRepository,
} from "../features/feedback/domain/domain";
import {
  BatteryRemoteDataSource,
  BatteryRepository,
} from "../features/battery/battery";
import { MatchmakingLocaleDataSource } from "../features/match_making/domain/match-making-local-data-source";

const cacheManager = new CacheManager();

const authLocaleDataSource = new AuthLocaleDataSource(cacheManager);

const httpClient = new HttpClient(createHttpClient(authLocaleDataSource));

export const authRepository = new AuthRepository(
  new AuthRemoteDataSource(httpClient),
  authLocaleDataSource
);

export const socketClient = new SocketClient(authRepository);

export const profileRepository = new ProfileRepository(
  new ProfileRemoteDataSource(httpClient)
);

export const chatRepository = new ChatRepository(
  new ChatRemoteDataSource(httpClient),
  new ChatSocketDataSource(socketClient)
);

export const walletRepository = new WalletRepository(
  new WalletRemoteDataSource(httpClient)
);

export const batteryRepository = new BatteryRepository(
  new BatteryRemoteDataSource(httpClient)
);

export const matchmakingRepository = new MatchmakingRepository(
  new MatchmakingSocketDataSource(socketClient),
  new MatchmakingRemoteDataSource(httpClient),
  new MatchmakingLocaleDataSource(cacheManager)
);

export const paymentRepository = new PaymentRepository(
  new PaymentRemoteDataSource(httpClient)
);

export const promoCodeRepository = new PromoCodeRepository(
  new PromoCodeRemoteDataSource(httpClient)
);

export const referralRepository = new ReferralRepository(
  new ReferralRemoteDataSource(httpClient)
);

export const firebaseRepository = new FirebaseRepository(
  new FirebaseRemoteDataSource(httpClient),
  new FirebaseLocaleDataSource(cacheManager)
);

export const feedbackRepository = new FeedbackRepository(
  new FeedbackRemoteDataSource(httpClient)
);
