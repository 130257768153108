import { Modal, ModalContent, ModalBody } from "@heroui/react";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PromoCode } from "./PromoCode";

export function usePromoCodeModal(): {
  promoCodeModal: React.ReactNode;
  openPromoCodeModal: () => void;
} {
  const navigate = useNavigate();
  const location = useLocation();
  const isProfileOpen = location.hash === "#promo-code";
  const onOpen = useCallback(() => {
    navigate(location.pathname + "#promo-code");
  }, [location.pathname, navigate]);
  const onOpenChange = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        onOpen();
      } else {
        history.back();
      }
    },
    [onOpen]
  );

  return {
    promoCodeModal: (
      <Modal
        isOpen={isProfileOpen}
        onOpenChange={onOpenChange}
        hideCloseButton={true}
        size="lg"
        backdrop="blur"
      >
        <ModalContent>
          <ModalBody className="p-4">
            <PromoCode />
          </ModalBody>
        </ModalContent>
      </Modal>
    ),
    openPromoCodeModal: onOpen,
  };
}
