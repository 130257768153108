import {
  DomainFailure,
  nothing,
  Nothing,
  RemoteDataSource,
  Result,
} from "../../../core/core";
import { BatteryState } from "./domain";

export class BatteryRemoteDataSource extends RemoteDataSource {
  async get(): Promise<Result<DomainFailure, BatteryState>> {
    const result = await this.httpClient.get("/battery");

    return result.map((data) => data as BatteryState);
  }

  async recharge(): Promise<Result<DomainFailure, Nothing>> {
    const result = await this.httpClient.post("/battery/recharge");

    return result.map(() => nothing);
  }

  async boost(): Promise<Result<DomainFailure, Nothing>> {
    const result = await this.httpClient.post("/battery/boost");

    return result.map(() => nothing);
  }
}
