import { DomainFailure, Nothing, Result } from "../../../core/core";
import { BatteryRemoteDataSource } from "./battery-remote-data-source";
import { BatteryState } from "./entities/entities";

export class BatteryRepository {
  constructor(private readonly _remoteDataSource: BatteryRemoteDataSource) {}

  get(): Promise<Result<DomainFailure, BatteryState>> {
    return this._remoteDataSource.get();
  }

  recharge(): Promise<Result<DomainFailure, Nothing>> {
    return this._remoteDataSource.recharge();
  }

  boost(): Promise<Result<DomainFailure, Nothing>> {
    return this._remoteDataSource.boost();
  }
}
