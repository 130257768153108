import {
  DomainFailure,
  nothing,
  Nothing,
  RemoteDataSource,
  Result,
} from "../../../core/core";
import { Json } from "../../../core/data/core/json";
import {
  CompleteProfileRequest,
  Profile,
  UpdateProfileRequest,
} from "./models/models";

export default class ProfileRemoteDataSource extends RemoteDataSource {
  public async get(): Promise<Result<DomainFailure, Profile>> {
    const result = await this.httpClient.get("/profile");

    return result.map((data) => data as Profile);
  }

  public async complete(
    request: CompleteProfileRequest
  ): Promise<Result<DomainFailure, Nothing>> {
    const { birthday } = request;
    const result = await this.httpClient.post("/profile", {
      body: {
        ...request,
        birthday: `${birthday.getFullYear()}-${(birthday.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${birthday.getDate().toString().padStart(2, "0")}`,
      },
    });

    return result.map(() => nothing);
  }

  public async update(
    request: UpdateProfileRequest
  ): Promise<Result<DomainFailure, Profile>> {
    const result = await this.httpClient.patch("/profile", {
      body: request as Json,
    });

    return result.map((data) => data as Profile);
  }
}
