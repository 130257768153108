import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@heroui/react";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import {
  copyTextToClipboard,
  FontAwesomeSvgIcon,
} from "../../../../../core/core";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  AnalyticsEvents,
  logAnalyticsEvent,
} from "../../../../firebase/firebase";

export function PaymentFailure(props: { orderId: string }) {
  const navigate = useNavigate();

  const copyFollowUpCode = useCallback(() => {
    logAnalyticsEvent(AnalyticsEvents.paymentResult.copyOrderIdClicked, {
      order_id: props.orderId,
    });

    copyTextToClipboard(props.orderId);

    toast.info("کد پیگیری کپی شد.");
  }, [props.orderId]);

  return (
    <div className={`flex flex-col justify-center md:h-auto p-4 h-dvh`}>
      <div className="flex-grow flex flex-col justify-center items-center">
        <FontAwesomeSvgIcon
          icon={faCircleXmark}
          className="text-danger"
          size="9x"
        />
        <h1 className="text-xl font-medium mt-6 text-center">
          پرداخت شما ناموفق بود!
        </h1>
        <h2 className="text-center mt-4">
          در صورت كسر از موجودى، مبلغ پرداختى طى يك تا ٧٢ ساعت آينده به حساب شما
          بازگردانده خواهد شد.
        </h2>
      </div>

      <div className="flex flex-col items-stretch gap-2 py-4">
        <h1 className="font-medium">کد پیگیری:</h1>
        <div className="flex flex-row justify-between py-1 px-2 items-center bg-gray-100 rounded-[8px] ">
          <Button
            isIconOnly
            size="sm"
            variant="light"
            className="ml-2"
            onPress={copyFollowUpCode}
          >
            <FontAwesomeSvgIcon icon={faCopy} size="xl" />
          </Button>
          <h2
            className="font-mono text-xs line-clamp-1 text-left"
            style={{ direction: "ltr" }}
          >
            {props.orderId}
          </h2>
        </div>
      </div>

      <Button
        variant="solid"
        color="primary"
        onPress={() => {
          logAnalyticsEvent(
            AnalyticsEvents.paymentResult.failureReturnToHomeButtonClicked
          );
          navigate("/");
        }}
      >
        بازگشت به صفحه اصلی
      </Button>
    </div>
  );
}
