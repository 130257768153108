import { faListCheck } from "@fortawesome/free-solid-svg-icons";
import { Config, FontAwesomeSvgIcon } from "../../../core/core";
import Logo from "/logo.png";
import { ReactNode, useCallback } from "react";
import { Link } from "@heroui/react";

type TermItem = {
  title: string;
  description?: string;
  items?: TermItem[];
};

export function TermsPage() {
  const renderTerms = useCallback(
    (items: TermItem[], isTopLevel = false): ReactNode => {
      return (
        <>
          {items.map((term) => {
            if (term.items) {
              return (
                <li>
                  <span className="text-lg font-medium">{term.title}</span>
                  <ol className="list-decimal list-inside pr-6 space-y-2 py-2">
                    {renderTerms(term.items)}
                  </ol>
                </li>
              );
            }
            const hasDescription = !!term.description;

            return (
              <li>
                <span
                  className={`${hasDescription ? "font-medium" : ""} ${
                    isTopLevel ? "text-lg" : ""
                  }`}
                >
                  {term.title}
                </span>
                {hasDescription ? (
                  isTopLevel ? (
                    <p className="pr-6">{term.description}</p>
                  ) : (
                    <>{`: ${term.description}`}</>
                  )
                ) : (
                  <></>
                )}
              </li>
            );
          })}
        </>
      );
    },
    []
  );

  return (
    <div className="flex flex-col h-screen">
      <div className="flex items-center justify-center shadow-md">
        <img src={Logo} alt="Fandogh Logo" width={42} />
        <h1 className="p-4 text-3xl" style={{ textAlign: "center" }}>
          فندق
        </h1>
      </div>
      <div className="flex-grow overflow-scroll">
        <div className="p-6 pt-0 max-w-screen-md text-justify m-auto flex flex-col items-stretch">
          <div className="flex flex-row items-center py-4">
            <FontAwesomeSvgIcon icon={faListCheck} size="2xl" />
            <h1 className="pr-4 text-2xl">قوانین و مقررات</h1>
          </div>
          <p>
            با ثبت‌نام و استفاده از خدمات پلتفرم فندق، شما موافقت خود را با
            قوانین و مقررات زیر اعلام می‌کنید. لطفاً این قوانین را به‌دقت مطالعه
            کنید.
          </p>
          <ol className="list-decimal list-inside space-y-2 py-4">
            {renderTerms(terms, true)}
          </ol>

          <p>
            در صورت نیاز به توضیحات بیشتر یا گزارش تخلفات، می‌توانید با تیم
            پشتیبانی فندق در تماس باشید.
          </p>

          <p className="py-4">
            <span className="font-medium">ایمیل پشتیبانی: </span>
            <Link href={`mailto:${Config.supportEmailAddress}`}>
              {Config.supportEmailAddress}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

const terms: TermItem[] = [
  {
    title: "تعاریف و کلیات",
    items: [
      {
        title: "فندق",
        description:
          "یک پلتفرم چت ناشناس که امکان مکالمه با افراد دیگر براساس جنسیت انتخاب‌شده را فراهم می‌کند.",
      },
      {
        title: "کاربر",
        description: "هر شخصی که از خدمات فندق استفاده می‌کند.",
      },
      {
        title: "توکن",
        description: "واحد اعتباری برای استفاده از خدمات ویژه در فندق.",
      },
      {
        title: "سیستم ریفرال",
        description: "سیستمی برای دعوت از دیگران و دریافت پاداش.",
      },
      {
        title: "پرومو کد",
        description:
          "کد تخفیف برای خرید توکن که می‌تواند درآمدزایی برای کاربر داشته باشد.",
      },
    ],
  },
  {
    title: "شرایط استفاده از خدمات",
    items: [
      {
        title: "سن مجاز",
        description:
          "استفاده از پلتفرم فندق فقط برای افراد بالای ۱۸ سال مجاز است.",
      },
      {
        title: "ثبت‌نام",
        description:
          "برای استفاده از خدمات، کاربر موظف به ارائه اطلاعات صحیح و واقعی (مانند جنسیت) است.",
      },
      {
        title: "استفاده مسئولانه",
        description:
          "کاربر متعهد می‌شود که از خدمات پلتفرم برای مقاصد قانونی استفاده کند و از هرگونه رفتار نامناسب خودداری نماید.",
      },
      {
        title: "ممنوعیت سوءاستفاده",
        description:
          "هرگونه استفاده غیرمجاز، سوءاستفاده از خدمات، ایجاد حساب‌های جعلی یا تقلب در سیستم ریفرال و پرومو کد اکیداً ممنوع است.",
      },
    ],
  },
  {
    title: "قوانین چت ناشناس",
    items: [
      {
        title: "رعایت احترام",
        description:
          "کاربران موظف‌اند در مکالمات خود احترام طرف مقابل را حفظ کنند و از ارسال پیام‌های توهین‌آمیز، تهدیدآمیز، توهین به عقاید، یا مطالب غیرقانونی خودداری کنند.",
      },
      {
        title: "ارسال محتوا",
        items: [
          {
            title: "محتواهای ممنوع",
            description:
              "ارسال هرگونه محتوای نامناسب، غیراخلاقی، پورنوگرافی، خشونت‌آمیز یا خلاف قوانین کشور ممنوع است.",
          },
          {
            title: "اطلاعات شخصی",
            description:
              "به اشتراک‌گذاری اطلاعات شخصی (مانند شماره تلفن، آدرس، شبکه‌های اجتماعی) در چت‌ها اکیداً توصیه نمی‌شود.",
          },
        ],
      },
      {
        title: "گزارش تخلف",
        description:
          "کاربران می‌توانند هرگونه تخلف را از طریق بخش پشتیبانی گزارش دهند. فندق حق مسدودسازی حساب‌های متخلف را برای خود محفوظ می‌دارد.",
      },
    ],
  },
  {
    title: "قوانین سیستم ریفرال و پاداش",
    items: [
      {
        title: "استفاده صحیح از لینک ریفرال",
        description:
          "لینک ریفرال باید به‌طور قانونی و اخلاقی به اشتراک گذاشته شود. هرگونه تقلب (مانند ایجاد حساب‌های جعلی) ممنوع است.",
      },
      {
        title: "محاسبه پاداش",
        description:
          "پاداش ریفرال‌ها به‌صورت توکن ارائه می‌شود و فندق حق تغییر مقدار پاداش را در هر زمان برای خود محفوظ می‌دارد.",
      },
      {
        title: "مشاهده و برداشت",
        description:
          "کاربران می‌توانند از بخش حساب کاربری وضعیت ریفرال‌های خود و پاداش‌های دریافتی را مشاهده کنند.",
      },
    ],
  },
  {
    title: "قوانین سیستم پرومو کد و درآمدزایی",
    items: [
      {
        title: "ایجاد و استفاده از کد",
        description:
          "کاربران می‌توانند کدهای تخفیف دریافت کرده یا بسازند و آن را به اشتراک بگذارند.",
      },
      {
        title: "کمیسیون",
        description:
          "صاحب کد به ازای خریدهایی که با کد او انجام می‌شود، کمیسیون مشخصی دریافت خواهد کرد.",
      },
      {
        title: "شرایط برداشت",
        description:
          "درآمد حاصل از پرومو کد زمانی قابل برداشت است که به حد نصاب مشخص‌شده توسط فندق برسد. برداشت‌ها به‌صورت ماهانه انجام می‌شود.",
      },
      {
        title: "ممنوعیت تخلف",
        description:
          "هرگونه سوءاستفاده از کدهای تخفیف (مانند خریدهای تقلبی) منجر به مسدود شدن حساب کاربر و لغو کمیسیون‌ها خواهد شد.",
      },
    ],
  },
  {
    title: "مسئولیت‌ها و محدودیت‌ها",
    items: [
      {
        title: "مسئولیت محتوا",
        description:
          "کاربران مسئول تمام محتوایی هستند که در چت‌ها ارسال می‌کنند. فندق مسئولیتی در قبال پیام‌ها و محتواهای ردوبدل‌شده بین کاربران ندارد.",
      },
      {
        title: "قطع و تغییر خدمات",
        description:
          "فندق حق دارد در هر زمان خدمات خود را تغییر دهد یا موقتاً قطع کند.",
      },
      {
        title: "امنیت حساب",
        description:
          "کاربران موظف‌اند از لینک‌ها و حساب کاربری خود محافظت کنند. هرگونه فعالیت مشکوک باید فوراً به تیم پشتیبانی اطلاع داده شود.",
      },
    ],
  },
  {
    title: "نقض قوانین",
    description:
      "در صورت نقض هر یک از قوانین بالا، فندق می‌تواند اقدامات زیر را انجام دهد:",
    items: [
      {
        title: "هشدار به کاربر",
        description: "",
      },
      {
        title: "مسدودسازی موقت یا دائم حساب کاربری",
        description: "",
      },
      {
        title: "لغو پاداش‌ها و درآمدهای تقلبی",
        description: "",
      },
      {
        title: "ارجاع به مراجع قانونی در صورت لزوم",
        description: "",
      },
    ],
  },
  {
    title: "تغییرات قوانین",
    description:
      "فندق حق دارد در هر زمان این قوانین را تغییر دهد. تغییرات مهم از طریق اطلاعیه در وب‌سایت یا اپلیکیشن به کاربران اعلام خواهد شد. استفاده مستمر از خدمات پس از تغییرات به‌منزله پذیرش قوانین جدید است.",
  },
];
