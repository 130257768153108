import React from "react";
import { BatteryState } from "../../battery";

export const Battery: React.FC<{
  state: BatteryState;
  mini?: boolean;
  multiplier?: number;
}> = ({ state, mini = false, multiplier }) => {
  // TODO: Glow or cool borders for premium users

  let batteryColor: string = "#4CAF50";
  if (state.charge <= state.capacity / 4) {
    batteryColor = "#FF4D4D";
  }

  if (mini) {
    return (
      <div className="flex flex-row h-6 w-12 justify-center items-center">
        <div className="w-1 h-2 bg-black rounded-r-md" />
        <div className="w-full h-full border-2 border-black rounded-lg p-0.5 flex flex-row-reverse relative">
          <div
            className="h-full rounded-md transition-all"
            style={{
              backgroundColor: batteryColor,
              width: `${(state.charge * 100) / state.capacity}%`,
            }}
          />
          <div className="absolute top-1 bottom-0 left-0 right-0 flex items-center justify-center text-black text-sm">
            {state.charge}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-row h-32 justify-center items-center">
      <div className="w-3 h-7 bg-black rounded-r-md" />
      <div className="w-full h-full border-4 border-black rounded-2xl flex flex-row-reverse gap-1 p-1.5 relative">
        <div
          className="h-full rounded-lg transition-all"
          style={{
            backgroundColor: batteryColor,
            width: `${(state.charge * 100) / state.capacity}%`,
          }}
        />
        <div className="absolute top-1 bottom-0 left-0 right-0 flex items-center justify-center text-black text-6xl mt-3">
          {multiplier && (
            <div className="ml-2 w-8 h-8 rounded-full border-1 border-black flex justify-center items-center">
              <span className="text-xl font-thin mt-1">
                {multiplier}
                <span className="text-lg">X</span>
              </span>
            </div>
          )}
          <span>{state.charge}</span>
        </div>
      </div>
    </div>
  );
};
