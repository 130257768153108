import React from "react";
import { Modal } from "@heroui/react";

interface Props extends React.HTMLProps<HTMLDivElement> {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}

export const Drawer: React.FC<Props> = ({ ...props }) => {
  return (
    <Modal
      scrollBehavior="inside"
      isOpen={props.isOpen}
      onOpenChange={props.onOpenChange}
      hideCloseButton
      placement="center"
      backdrop="opaque"
      size="full"
      classNames={{
        wrapper: "flex justify-start",
      }}
      motionProps={{
        variants: {
          enter: {
            x: 0,
            opacity: 1,
            transition: {
              duration: 0.1,
              ease: "easeOut",
            },
          },
          exit: {
            x: 50,
            opacity: 0,
            transition: {
              duration: 0.1,
              ease: "easeIn",
            },
          },
        },
      }}
      className="w-1/4 min-w-80 h-[calc(100dvh)] max-h-screen"
    >
      {props.children}
    </Modal>
  );
};
