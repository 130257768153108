import {
  DomainFailure,
  nothing,
  Nothing,
  RemoteDataSource,
  Result,
} from "../../../core/core";

export class FirebaseRemoteDataSource extends RemoteDataSource {
  async sendToken(fcmToken: string): Promise<Result<DomainFailure, Nothing>> {
    const result = await this.httpClient.patch("/session", {
      body: { fcmToken },
    });

    return result.map(() => nothing);
  }
}
