import Axios, {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { authRepository, EnvironmentVariables, useStore } from "../../core";
import AuthLocaleDataSource from "../../../features/auth/data/auth-local-data-source";
import createAuthRefreshInterceptor from "axios-auth-refresh";

export function createHttpClient(
  authLocaleDataSource: AuthLocaleDataSource
): AxiosInstance {
  const axiosClient = Axios.create({
    baseURL: `${EnvironmentVariables.apiBaseUrl}/api/v1`,
    headers: {
      "Content-Type": "application/json",
    },
    timeout: 15000,
    responseType: "json",
    validateStatus: (status) => status >= 200 && status < 300,
  });

  axiosClient.interceptors.request.use((config) => {
    logRequest(config);
    return config;
  });

  axiosClient.interceptors.request.use((request) => {
    if (request.url === "/auth/renew-token") {
      const refreshToken = authLocaleDataSource.getCredentials()?.refreshToken;
      if (refreshToken) {
        request.headers.Authorization = `Bearer ${refreshToken}`;
        return request;
      }
    }

    const accessToken = authLocaleDataSource.getCredentials()?.accessToken;
    if (accessToken) {
      request.headers.Authorization = `Bearer ${accessToken}`;
    }
    return request;
  });

  // TODO:
  // if (isDebugMode()) {

  axiosClient.interceptors.response.use(
    (response) => {
      logResponse(response);
      return response;
    },
    (error: AxiosError) => {
      logError(error);

      return Promise.reject(error);
    }
  );

  axiosClient.interceptors.response.use((response) => {
    handleDates(response.data);
    return response;
  });
  // }

  createAuthRefreshInterceptor(
    axiosClient,
    async (failedRequest) => {
      if (failedRequest.config.url === "/auth/verify") {
        return;
      }

      const result = await authRepository.renewToken();

      return result.fold({
        onFailure: (failure) => {
          failure.fold({
            onNetworkFailure: () => {},
            onServerFailure: () => {
              authRepository.removeCredentials();
              window.location.href = "/auth/phone";
              setTimeout(() => useStore.getState().resetState(), 500);
            },
          });
        },
        onSuccess: () => {
          failedRequest.response.config.headers.Authorization = `Bearer ${
            authLocaleDataSource.getCredentials()?.accessToken
          }`;
        },
      });
    },
    {
      pauseInstanceWhileRefreshing: true,
    }
  );

  return axiosClient;
}

function logRequest(request: InternalAxiosRequestConfig): void {
  const { url, baseURL, params, data, headers, method } = request;
  console.log(
    `[${dateToString()}]%c [Http Request]%c\n${method?.toUpperCase()} ${baseURL}${url}`,
    "color: #fff000",
    "",
    "\nparams:",
    params,
    "\ndata:",
    data,
    "\nheaders:",
    headers
  );
}

function logResponse(response: AxiosResponse): void {
  const { data, headers, status, config } = response;
  const { method, params, url, baseURL } = config;

  console.log(
    `[${dateToString()}]%c [Http Response]%c\n${method?.toUpperCase()} ${status} ${baseURL}${url}`,
    "color: #fff000",
    "",
    "\nparams:",
    params,
    "\ndata:",
    data,
    "\nheaders:",
    headers
  );
}

function logError(error: AxiosError): void {
  const { response, config } = error;

  console.log(
    `[${dateToString()}]%c [Http Response]%c\n${config?.method?.toUpperCase()} ${
      response?.status
    } ${config?.baseURL ?? "" + config?.url}`,
    "color: #fff000",
    "",
    "\nparams:",
    response?.config.params,
    "\ndata:",
    response?.data,
    "\nheaders:",
    config?.headers
  );
}

function dateToString() {
  return new Date().toISOString();
}

//

const ISODateFormat =
  /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

const otherDateFormat =
  /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}(?:\.\d*)?(?:[-+]\d{2}:?\d{2}|Z)?$/;

const isIsoDateString = (value: unknown): value is string => {
  return (
    typeof value === "string" &&
    (ISODateFormat.test(value) || otherDateFormat.test(value))
  );
};

export const handleDates = (data: unknown) => {
  if (isIsoDateString(data)) {
    return new Date(data.endsWith("Z") ? data : data + "Z");
  }
  if (data === null || data === undefined || typeof data !== "object") {
    return data;
  }

  for (const [key, val] of Object.entries(data)) {
    if (isIsoDateString(val)) {
      // @ts-expect-error this is a hack to make the type checker happy
      data[key] = new Date(val.endsWith("Z") ? val : val + "Z");
    } else if (typeof val === "object") handleDates(val);
  }

  return data;
};
