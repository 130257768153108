import { Button, Input, Spinner } from "@heroui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  AsyncAction,
  AsyncValue,
  DomainFailure,
  FailureView,
  FontAwesomeSvgIcon,
  paymentRepository,
  promoCodeRepository,
  showErrorToast,
  toPersianDigits,
  toPersianPrice,
} from "../../../../core/core";
import { faChevronRight, faTag } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { Discount, SubscriptionPlan } from "../../domain/domain";
import { PromoCode } from "../../../promo-code/domain/domain";
import { toast } from "react-toastify";
import { AnalyticsEvents, logAnalyticsEvent } from "../../../firebase/firebase";
import { SubscriptionPlanCard } from "../../plans/view/components/SubscriptionPlanCard";

type PaymentCheckoutSuccessState = {
  plan: SubscriptionPlan;
  offCode: string;
  searchDiscountAction: AsyncAction<DomainFailure, Discount>;
  searchPromoCodeAction: AsyncAction<DomainFailure, PromoCode>;
  startPaymentAction: AsyncAction<DomainFailure, string>;
};
type PaymentCheckoutState = AsyncValue<
  DomainFailure,
  PaymentCheckoutSuccessState
>;

export function PaymentCheckout() {
  const { selectedPlanId } = useParams();
  const navigate = useNavigate();

  const [state, setState] = useState<PaymentCheckoutState>(
    AsyncValue.initial()
  );

  const loadPlan = useCallback(async () => {
    setState(AsyncValue.loadInProgress());

    const plans = await paymentRepository.getSubscriptions();

    setState(
      plans.fold({
        onFailure: AsyncValue.loadFailure,
        onSuccess: (plans) => {
          return AsyncValue.loadSuccess<
            DomainFailure,
            PaymentCheckoutSuccessState
          >({
            plan: plans.find((plan) => plan.id === selectedPlanId)!,
            offCode: "",
            searchDiscountAction: AsyncAction.initial(),
            searchPromoCodeAction: AsyncAction.initial(),
            startPaymentAction: AsyncAction.initial(),
          });
        },
      })
    );
  }, [selectedPlanId]);

  useEffect(() => {
    loadPlan();
  }, [loadPlan]);

  const onOffCodeChanged = useCallback(
    (code: string) => {
      state.maybeFold({
        loadSuccess: (state) => {
          setState(
            AsyncValue.loadSuccess({
              ...state,
              offCode: code.trim(),
            })
          );
        },
        orElse: () => {},
      });
    },
    [state]
  );

  const onOffCodeSubmitted = useCallback(() => {
    state.maybeFold({
      loadSuccess: async (state) => {
        logAnalyticsEvent(
          AnalyticsEvents.paymentCheckout.applyDiscountButtonClicked,
          {
            discount_code: state.offCode,
          }
        );

        setState(
          AsyncValue.loadSuccess({
            ...state,
            searchDiscountAction: AsyncAction.loading(),
            searchPromoCodeAction: AsyncAction.loading(),
          })
        );

        const results = await Promise.all([
          promoCodeRepository.searchCode(state.offCode),
          paymentRepository.searchDiscount(state.offCode),
        ]);

        if (results[0].isSuccess || results[1].isSuccess) {
          toast.success("کد تخفیف با موفقیت اعمال شد!");
          setState(
            AsyncValue.loadSuccess({
              ...state,
              searchDiscountAction: results[1].isSuccess
                ? AsyncAction.result(results[1])
                : AsyncAction.initial(),
              searchPromoCodeAction: results[0].isSuccess
                ? AsyncAction.result(results[0])
                : AsyncAction.initial(),
            })
          );
        } else {
          const discountError = results[0].fold({
            onFailure: (failure) =>
              failure.fold({
                onNetworkFailure: () => null,
                onServerFailure: (failure) => failure.errorCode,
              }),
            onSuccess: () => null,
          });
          const promoCodeError = results[1].fold({
            onFailure: (failure) =>
              failure.fold({
                onNetworkFailure: () => null,
                onServerFailure: (failure) => failure.errorCode,
              }),
            onSuccess: () => null,
          });

          if (discountError === null && promoCodeError === null) {
            toast.error("ارتباط اینترنت خود را بررسی و مجدد امتحان کنید.");
          } else {
            showPaymentOrDiscountError(
              getMostImportantError(discountError, promoCodeError)
            );
          }

          setState(
            AsyncValue.loadSuccess({
              ...state,
              searchDiscountAction: AsyncAction.initial(),
              searchPromoCodeAction: AsyncAction.initial(),
            })
          );
        }
      },
      orElse: () => {},
    });
  }, [state]);

  const onOffCodeRemoved = useCallback(() => {
    logAnalyticsEvent(
      AnalyticsEvents.paymentCheckout.removeDiscountButtonClicked
    );

    state.maybeFold({
      loadSuccess: async (state) => {
        setState(
          AsyncValue.loadSuccess({
            ...state,
            offCode: "",
            searchDiscountAction: AsyncAction.initial(),
            searchPromoCodeAction: AsyncAction.initial(),
          })
        );
      },
      orElse: () => {},
    });
  }, [state]);

  const discountPercent = useMemo(
    () =>
      state.maybeFold({
        orElse: () => 0,
        loadSuccess: (state) =>
          state.searchDiscountAction.result?.fold({
            onSuccess: (discount) => discount.percent,
            onFailure: () => null,
          }) ??
          state.searchPromoCodeAction.result?.fold({
            onSuccess: (promoCode) => promoCode.discountPercent,
            onFailure: () => null,
          }) ??
          0,
      }),
    [state]
  );

  const discountAmount = useMemo(
    () =>
      state.maybeFold({
        orElse: () => 0,
        loadSuccess: (state) => state.plan.price * (discountPercent / 100),
      }),
    [discountPercent, state]
  );

  const totalPrice = useMemo(
    () =>
      state.maybeFold({
        orElse: () => 0,
        loadSuccess: (state) => state.plan.price - discountAmount,
      }),
    [discountAmount, state]
  );

  const onSubmitted = useCallback(() => {
    state.maybeFold({
      loadSuccess: async (state) => {
        setState(
          AsyncValue.loadSuccess({
            ...state,
            startPaymentAction: AsyncAction.loading(),
          })
        );

        const discountId =
          state.searchDiscountAction.result?.fold({
            onSuccess: (discount) => discount.id,
            onFailure: () => null,
          }) ?? null;
        const promoCodeId =
          state.searchPromoCodeAction.result?.fold({
            onSuccess: (promoCode) => promoCode.id,
            onFailure: () => null,
          }) ?? null;

        logAnalyticsEvent(AnalyticsEvents.paymentCheckout.payButtonClicked, {
          subscription_id: state.plan.id,
          subscription_name: state.plan.name,
          subscription_duration: state.plan.duration,
          subscription_price: state.plan.price,
          subscription_trail: state.plan.trail,
          subscription_battery_multiplier:
            state.plan.features.batteryMultiplier.value,
          total_price: totalPrice,
          off_code: state.offCode,
          discount_id: discountId ?? "",
          promo_code_id: promoCodeId ?? "",
        });
        logAnalyticsEvent("begin_checkout", {
          currency: "IRR",
          coupon: state.offCode,
          value: state.plan.price,
          items: "",
        });

        const result = await paymentRepository.createPayment({
          subscriptionId: selectedPlanId!,
          discountId,
          promoCodeId,
        });

        result.fold({
          onSuccess: (url) => {
            window.open(url, "_self");
          },
          onFailure: (failure) => {
            failure.fold({
              onNetworkFailure: () => showErrorToast(failure),
              onServerFailure: (failure) =>
                showPaymentOrDiscountError(failure.errorCode),
            });

            setState(
              AsyncValue.loadSuccess({
                ...state,
                startPaymentAction: AsyncAction.initial(),
              })
            );
          },
        });
      },
      orElse: () => {},
    });
  }, [selectedPlanId, state, totalPrice]);

  return (
    <div className="flex flex-col md:items-center justify-center h-dvh">
      {state.maybeFold({
        orElse: () => <Spinner />,
        loadFailure: (failure) => (
          <FailureView failure={failure} onRetry={loadPlan} />
        ),
        loadSuccess: (state) => {
          const priceString = toPersianPrice(state.plan.price.toString());

          return (
            <div className="w-auto md:w-96 h-full md:h-auto p-4 pt-2 flex flex-col justify-between">
              <div className="flex flex-row items-center h-14 gap-2">
                <Button
                  variant="light"
                  isIconOnly
                  radius="full"
                  isDisabled={state.startPaymentAction.isLoading}
                  onPress={() => {
                    logAnalyticsEvent(
                      AnalyticsEvents.paymentCheckout.backButtonClicked
                    );
                    navigate("/");
                  }}
                >
                  <FontAwesomeSvgIcon icon={faChevronRight} size="lg" />
                </Button>
                <h1 className="font-semibold">تکمیل خرید و پرداخت</h1>
              </div>

              <SubscriptionPlanCard plan={state.plan} isViewOnly />

              <div className="flex-grow mt-4">
                <h1 className="font-medium mb-2">کد تخفیف</h1>
                <div className="flex flex-row items-end gap-2">
                  <Input
                    variant="bordered"
                    labelPlacement="outside"
                    placeholder="کد تخفیف خود را وارد کنید"
                    startContent={<FontAwesomeSvgIcon icon={faTag} />}
                    value={state.offCode}
                    onValueChange={onOffCodeChanged}
                    isDisabled={state.startPaymentAction.isLoading}
                    readOnly={discountPercent !== 0}
                    onClick={() => {
                      logAnalyticsEvent(
                        AnalyticsEvents.paymentCheckout.discountInputClicked
                      );
                    }}
                  />
                  {discountPercent === 0 ? (
                    <Button
                      variant="solid"
                      color="primary"
                      isLoading={
                        state.searchDiscountAction.isLoading ||
                        state.searchPromoCodeAction.isLoading
                      }
                      isDisabled={
                        state.offCode.length === 0 ||
                        state.startPaymentAction.isLoading
                      }
                      onPress={onOffCodeSubmitted}
                    >
                      اعمال
                    </Button>
                  ) : (
                    <Button
                      variant="bordered"
                      color="danger"
                      isDisabled={state.startPaymentAction.isLoading}
                      onPress={onOffCodeRemoved}
                    >
                      حذف
                    </Button>
                  )}
                </div>
              </div>

              <div className="py-3 mt-4">
                {discountPercent !== 0 && (
                  <>
                    <div className="flex flex-row justify-between py-1.5">
                      <h1 className="font-medium">قیمت اصلی</h1>
                      <h2>{priceString} تومان</h2>
                    </div>
                    <div className="flex flex-row justify-between py-1.5">
                      <h1 className="font-medium text-danger">
                        سود شما از این خرید
                      </h1>
                      <h2 className="text-danger">
                        ({toPersianDigits(discountPercent.toString())}٪){" "}
                        {toPersianPrice(discountAmount.toString())} تومان
                      </h2>
                    </div>
                  </>
                )}
                <div className="flex flex-row justify-between py-1.5">
                  <h1 className="font-medium">مبلغ قابل پرداخت</h1>
                  <h2>{toPersianPrice(totalPrice.toString())} تومان</h2>
                </div>
              </div>

              <Button
                variant="solid"
                color="primary"
                fullWidth
                isLoading={state.startPaymentAction.isLoading}
                onPress={onSubmitted}
              >
                پرداخت
              </Button>
            </div>
          );
        },
      })}
    </div>
  );
}

enum OffCodeErrors {
  SelfPromoCodeForbidden = "SELF_PROMO_CODE_FORBIDDEN",
  DiscountExpired = "DISCOUNT_EXPIRED",
  DiscountUsageLimitReached = "DISCOUNT_USAGE_LIMIT_REACHED",
  DiscountNotActive = "DISCOUNT_NOT_ACTIVE",
  InvalidPromoCode = "INVALID_PROMO_CODE",
  InvalidDiscountCode = "INVALID_DISCOUNT_CODE",
  InvalidInput = "INVALID_INPUT",
  NotFound = "NOT_FOUND",
}

const errorsPriority: string[] = [
  "SUBSCRIPTION_ALREADY_ACTIVATED",
  OffCodeErrors.SelfPromoCodeForbidden,
  OffCodeErrors.DiscountExpired,
  OffCodeErrors.DiscountUsageLimitReached,
  OffCodeErrors.DiscountNotActive,
  OffCodeErrors.InvalidPromoCode,
  OffCodeErrors.InvalidDiscountCode,
  OffCodeErrors.InvalidInput,
  OffCodeErrors.NotFound,
];

function getMostImportantError(
  error1: string | null,
  error2: string | null
): string | null {
  if (error1 === null) {
    return error2;
  }

  if (error2 === null) {
    return error1;
  }

  const priority1 = errorsPriority.indexOf(error1);
  const priority2 = errorsPriority.indexOf(error2);

  if (priority1 === -1 && priority2 === -1) {
    return null;
  }

  // Return the error with the higher priority (lower index)
  return priority1 <= priority2 ? error1 : error2;
}

function showPaymentOrDiscountError(errorCode: string | null) {
  let errorMessage = "";
  switch (errorCode) {
    case OffCodeErrors.SelfPromoCodeForbidden:
      errorMessage = "شما از کد تخفیف متعلق به خود نمی‌توانید استفاده کنید!";
      break;
    case OffCodeErrors.DiscountExpired:
      errorMessage = "کد تخفیف منقضی شده است!";
      break;
    case OffCodeErrors.DiscountUsageLimitReached:
      errorMessage = "حداکثر تعداد استفاده از کد تخفیف به پایان رسیده است.";
      break;
    case OffCodeErrors.DiscountNotActive:
      errorMessage = "کد تخفیف فعال نیست!";
      break;
    case OffCodeErrors.InvalidDiscountCode:
    case OffCodeErrors.InvalidPromoCode:
    case OffCodeErrors.InvalidInput:
    case OffCodeErrors.NotFound:
      errorMessage = "کد تخفیف وارد شده معتبر نیست!";
      break;
    case "SUBSCRIPTION_ALREADY_ACTIVATED":
      errorMessage = "برای خرید اشتراک جدید، باید صبر کنی اشتراک فعلی تموم شه!";
  }

  toast.error(errorMessage ?? "مشکلی رخ داده است. لطفا مجدد تلاش کنید.");
}
