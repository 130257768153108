import {
  Modal,
  ModalContent,
  ModalBody,
  useDisclosure,
  ModalHeader,
  ModalFooter,
  Button,
} from "@heroui/react";
import { FontAwesomeSvgIcon, isMobile } from "../../../core/core";
import { faBell } from "@fortawesome/free-regular-svg-icons";

export function usePushNotificationPermissionModal(props: {
  onConfirmPressed: () => void;
}): {
  pushNotificationPermissionModal: React.ReactNode;
  openPushNotificationPermissionModal: () => void;
} {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return {
    pushNotificationPermissionModal: (
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        hideCloseButton={true}
        size="md"
        backdrop="opaque"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 p-4">
                <div className="flex flex-row gap-3">
                  <FontAwesomeSvgIcon icon={faBell} size="lg" />
                  <h1>فعالسازی نوتیفیکیشن‌ها</h1>
                </div>
              </ModalHeader>
              <ModalBody className="px-4">
                {Notification.permission === "default" ? (
                  <p>
                    با روشن کردن نوتیفیکیشن‌ها، همیشه از پیام‌های جدید باخبر
                    می‌شی.
                  </p>
                ) : (
                  <>
                    <p>
                      شما دسترسی به ارسال نوتیفیکیشن را رد کرده‌اید. برای دریافت
                      جدیدترین اطلاعیه‌ها، لطفاً دسترسی نوتیفیکیشن را فعال کنید.
                    </p>
                    <p>برای انجام این کار:</p>
                    <ul className="list-disc px-4 space-y-2">
                      <PermissionSteps />
                    </ul>
                  </>
                )}
              </ModalBody>
              <ModalFooter className="p-4">
                {Notification.permission === "default" ? (
                  <>
                    <Button
                      color="default"
                      variant="bordered"
                      onPress={onClose}
                      fullWidth
                    >
                      بعداً
                    </Button>
                    <Button
                      color="primary"
                      onPress={() => {
                        onClose();
                        props.onConfirmPressed();
                      }}
                      fullWidth
                    >
                      فعال کن
                    </Button>
                  </>
                ) : (
                  <Button
                    color="primary"
                    onPress={() => {
                      onClose();
                      props.onConfirmPressed();
                    }}
                    fullWidth
                  >
                    متوجه شدم
                  </Button>
                )}
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    ),
    openPushNotificationPermissionModal: onOpen,
  };
}

// TODO: Add Firefox
// TODO: Check and improve other browsers as well
function PermissionSteps() {
  const isOnMobile = isMobile();

  let steps: string[] = [
    "روی آیکون سه نقطه در گوشه بالای مرورگر کلیک کنید.",
    "وارد بخش Settings (تنظیمات) شوید.",
    "به قسمت Site Settings (تنظیمات سایت) بروید.",
    "گزینه Notifications (اعلان‌ها) را انتخاب کنید.",
    "سایت ما را در لیست پیدا کنید و وضعیت آن را به Allow (اجازه دادن) تغییر دهید.",
  ];

  if (navigator.userAgent.indexOf("Chrome") != -1) {
    if (isOnMobile) {
      steps = [
        "روی آیکون سه نقطه در گوشه بالای مرورگر کلیک کنید.",
        "وارد بخش Settings (تنظیمات) شوید.",
        "به قسمت Site Settings (تنظیمات سایت) بروید.",
        "گزینه Notifications (اعلان‌ها) را انتخاب کنید.",
        "سایت ما را در لیست پیدا کنید و وضعیت آن را به Allow (اجازه دادن) تغییر دهید.",
      ];
    } else {
      steps = [
        "روی آیکون سه نقطه در گوشه بالای مرورگر کلیک کنید.",
        "به قسمت Settings (تنظیمات) بروید.",
        "در قسمت Privacy and Security (حریم خصوصی و امنیت) گزینه Site Settings (تنظیمات سایت) را انتخاب کنید.",
        "در بخش Notifications (اعلان‌ها)، سایت ما را پیدا کنید و وضعیت دسترسی را به Allow (اجازه دادن) تغییر دهید.",
      ];
    }
  } else if (navigator.userAgent.indexOf("Safari") != -1) {
    steps = [
      "از نوار منوی بالای صفحه، به Safari > Settings (تنظیمات) بروید.",
      "به بخش Websites (وب‌سایت‌ها) بروید.",
      "در ستون سمت چپ، گزینه Notifications (اعلان‌ها) را انتخاب کنید.",
      "سایت ما را در لیست پیدا کنید و وضعیت آن را به Allow (اجازه دادن) تغییر دهید.",
    ];
  }

  return (
    <>
      {steps.map((step) => (
        <li>{step}</li>
      ))}
    </>
  );
}
