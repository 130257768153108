import { DomainFailure, RemoteDataSource, Result } from "../../../core/core";
import { OwnPromoCode } from "./entities/entities";

export class PromoCodeRemoteDataSource extends RemoteDataSource {
  async getOwnCode(): Promise<Result<DomainFailure, OwnPromoCode>> {
    const result = await this.httpClient.get("/promo-code");

    return result.map((data) => data as OwnPromoCode);
  }

  async getOwnCodeUsageCount(): Promise<Result<DomainFailure, number>> {
    const result = await this.httpClient.get("/promo-code/statistic");

    return result.map((data) => data["used"] as number);
  }

  async hasPendingRequest(): Promise<Result<DomainFailure, boolean>> {
    const result = await this.httpClient.get(
      "/commission-payout/request/pending"
    );

    return result.map((data) => data["hasPending"] as boolean);
  }

  async searchCode(code: string): Promise<Result<DomainFailure, OwnPromoCode>> {
    const result = await this.httpClient.get("/promo-code/search", {
      queryParameters: { code },
    });

    return result.map((data) => data as OwnPromoCode);
  }
}
