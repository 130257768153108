type OnSuccessCallback<R, T> = (data: T) => R;
type OnFailureCallback<R, T> = (failure: T) => R;

export abstract class Result<F, S> {
  public static success<F, S>(data: S): Success<F, S> {
    return new Success(data);
  }

  public static failure<F, S>(failure: F): Failure<F, S> {
    return new Failure(failure);
  }

  get isSuccess(): boolean {
    return this.fold({
      onSuccess: (_) => true,
      onFailure: (_) => false,
    });
  }

  get isFailure(): boolean {
    return this.fold({
      onSuccess: (_) => false,
      onFailure: (_) => true,
    });
  }

  /// Returns the result of calling [onSuccess] or [onFailure] based on the
  /// result type.
  abstract fold<R>(params: {
    onSuccess: OnSuccessCallback<R, S>;
    onFailure: OnFailureCallback<R, F>;
  }): R;

  /// Maps the [Success] value of `this`, acts like an identity if `this` is
  /// [Failure].
  abstract map<S2>(onSuccess: OnSuccessCallback<S2, S>): Result<F, S2>;

  /// Maps the value inside `this` using the [onFailure] if `this` is [Failure]
  /// or using [onSuccess] if `this` is [Success].
  abstract bimap<F2, S2>(params: {
    onSuccess: OnSuccessCallback<S2, S>;
    onFailure: OnFailureCallback<F2, F>;
  }): Result<F2, S2>;
}

class Success<F, S> extends Result<F, S> {
  constructor(readonly data: S) {
    super();
  }

  fold<R>(params: {
    onSuccess: OnSuccessCallback<R, S>;
    onFailure: OnFailureCallback<R, F>;
  }): R {
    return params.onSuccess(this.data);
  }

  map<S2>(onSuccess: OnSuccessCallback<S2, S>): Result<F, S2> {
    return new Success<F, S2>(onSuccess(this.data));
  }

  bimap<F2, S2>(params: {
    onSuccess: OnSuccessCallback<S2, S>;
    onFailure: OnFailureCallback<F2, F>;
  }): Result<F2, S2> {
    return new Success<F2, S2>(params.onSuccess(this.data));
  }

  override toString() {
    return `Success(${this.data})`;
  }
}

class Failure<F, S> extends Result<F, S> {
  constructor(readonly failure: F) {
    super();
  }

  fold<R>(params: {
    onSuccess: OnSuccessCallback<R, S>;
    onFailure: OnFailureCallback<R, F>;
  }): R {
    return params.onFailure(this.failure);
  }

  map<S2>(_: OnSuccessCallback<S2, S>): Result<F, S2> {
    return new Failure<F, S2>(this.failure);
  }

  bimap<F2, S2>(params: {
    onSuccess: OnSuccessCallback<S2, S>;
    onFailure: OnFailureCallback<F2, F>;
  }): Result<F2, S2> {
    return new Failure<F2, S2>(params.onFailure(this.failure));
  }

  override toString() {
    return `Failure(${this.failure})`;
  }
}
