import { DomainFailure, nothing, Nothing, Result } from "../../../core/core";
import { getFcmToken } from "./firebase";
import { FirebaseLocaleDataSource } from "./firebase-local-data-source";
import { FirebaseRemoteDataSource } from "./firebase-remote-data-source";

export class FirebaseRepository {
  constructor(
    private readonly _remoteDataSource: FirebaseRemoteDataSource,
    private readonly _localDataSource: FirebaseLocaleDataSource
  ) {}

  async sendToken(): Promise<Result<DomainFailure, Nothing>> {
    try {
      const fcmToken = await getFcmToken();

      if (fcmToken === this._localDataSource.getFcmToken()) {
        return Result.success(nothing);
      }

      const result = await this._remoteDataSource.sendToken(fcmToken);

      return result.map(() => {
        this._localDataSource.setFcmToken(fcmToken);

        return nothing;
      });
    } catch (error) {
      console.error(error);
      return Result.failure(DomainFailure.networkFailure());
    }
  }

  // Every 4 days
  private readonly _notificationPermissionAskAgainIntervalInMs =
    24 * 60 * 60 * 1000 * 4;

  shouldRequestNotificationPermission(): boolean {
    const lastRequestTime =
      this._localDataSource.getNotificationPermissionLastRequestedAt();

    const shouldRequestPermission = lastRequestTime
      ? new Date().getTime() - lastRequestTime.getTime() >=
        this._notificationPermissionAskAgainIntervalInMs
      : true;

    if (shouldRequestPermission) {
      this._localDataSource.setNotificationPermissionLastRequestedAt(
        new Date()
      );
    }

    return shouldRequestPermission;
  }
}
