import { Modal, ModalContent, ModalBody } from "@heroui/react";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Subscriptions from "./Subscriptions";

export function usePlansModal(): {
  plansModal: React.ReactNode;
  openPlansModal: () => void;
} {
  const navigate = useNavigate();
  const location = useLocation();
  const isProfileOpen = location.hash === "#plans";
  const onOpen = useCallback(() => {
    navigate(location.pathname + "#plans");
  }, [location.pathname, navigate]);
  const onOpenChange = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        onOpen();
      } else {
        history.back();
      }
    },
    [onOpen]
  );

  return {
    plansModal: (
      <Modal
        isOpen={isProfileOpen}
        onOpenChange={onOpenChange}
        hideCloseButton={true}
        size="5xl"
        backdrop="blur"
        scrollBehavior="inside"
      >
        <ModalContent>
          <ModalBody className="p-4">
            <Subscriptions />
          </ModalBody>
        </ModalContent>
      </Modal>
    ),
    openPlansModal: onOpen,
  };
}
