export abstract class DomainFailure {
  public static networkFailure() {
    return new NetworkFailure();
  }

  public static serverFailure(params: {
    statusCode: number;
    errorCode: string | null;
  }) {
    return new ServerFailure(params.statusCode, params.errorCode);
  }

  abstract fold<R>(params: {
    onNetworkFailure: () => R;
    onServerFailure: (failure: ServerFailure) => R;
  }): R;
}

export class NetworkFailure extends DomainFailure {
  fold<R>(params: {
    onNetworkFailure: () => R;
    onServerFailure: (failure: ServerFailure) => R;
  }): R {
    return params.onNetworkFailure();
  }

  override toString() {
    return "NetworkFailure()";
  }
}

export class ServerFailure extends DomainFailure {
  fold<R>(params: {
    onNetworkFailure: () => R;
    onServerFailure: (failure: ServerFailure) => R;
  }): R {
    return params.onServerFailure(this);
  }

  constructor(readonly statusCode: number, readonly errorCode: string | null) {
    super();
  }

  override toString() {
    return `ServerFailure(statusCode: ${this.statusCode}, errorCode: ${this.errorCode})`;
  }
}
