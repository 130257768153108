import { map, Observable } from "rxjs";
import {
  DomainFailure,
  nothing,
  Nothing,
  Result,
  SocketDataSource,
} from "../../../core/core";
import {
  ChatMessage,
  ChatMessageWithRoomId,
  DeleteMessages,
  DeleteMessagesRequest,
  IsTyping,
  IsTypingRequest,
  SeenMessages,
  SeenMessagesRequest,
  SendMessageRequest,
  UpdateMessageRequest,
} from "./entities/entities";

export class ChatSocketDataSource extends SocketDataSource {
  async sendMessage(
    params: SendMessageRequest
  ): Promise<Result<DomainFailure, ChatMessage>> {
    const result = await this.socketClient.send({
      action: "send_message",
      payload: Object.fromEntries(
        Object.entries(params).filter((e) => e[1] != null)
      ),
    });

    return result.map((data) => data as ChatMessage);
  }

  async updateMessage(
    params: UpdateMessageRequest
  ): Promise<Result<DomainFailure, ChatMessage>> {
    const result = await this.socketClient.send({
      action: "update_message",
      payload: params,
    });

    return result.map((data) => data as ChatMessage);
  }

  async seenMessages(
    params: SeenMessagesRequest
  ): Promise<Result<DomainFailure, Nothing>> {
    const result = await this.socketClient.send({
      action: "seen_message",
      payload: params,
    });

    return result.map((_) => nothing);
  }

  async deleteMessages(
    params: DeleteMessagesRequest
  ): Promise<Result<DomainFailure, Nothing>> {
    const result = await this.socketClient.send({
      action: "delete_message",
      payload: params,
    });

    return result.map((_) => nothing);
  }

  async sendIsTyping(
    params: IsTypingRequest
  ): Promise<Result<DomainFailure, Nothing>> {
    const result = await this.socketClient.send(
      {
        action: "typing",
        payload: params,
      },
      false
    );

    return result.map((_) => nothing);
  }

  watchNewMessage(): Observable<ChatMessageWithRoomId> {
    return this.socketClient
      .listen("receive_message")
      .pipe(map((data) => data as ChatMessageWithRoomId));
  }

  watchUpdateMessage(): Observable<ChatMessageWithRoomId> {
    return this.socketClient
      .listen("update_message")
      .pipe(map((data) => data as ChatMessageWithRoomId));
  }

  watchSeenMessages(): Observable<SeenMessages> {
    return this.socketClient
      .listen("seen_message")
      .pipe(map((data) => data as SeenMessages));
  }

  watchDeleteMessages(): Observable<DeleteMessages> {
    return this.socketClient
      .listen("delete_message")
      .pipe(map((data) => data as DeleteMessages));
  }

  watchIsTyping(): Observable<IsTyping> {
    return this.socketClient
      .listen("typing")
      .pipe(map((data) => data as IsTyping));
  }

  watchReloadRooms(): Observable<Nothing> {
    return this.socketClient
      .listen("reload_chat_list")
      .pipe(map((_) => nothing));
  }
}
