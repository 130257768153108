import { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";

export function ReferralRoute() {
  const { inviteCode } = useParams();

  useEffect(() => {
    if (inviteCode) {
      localStorage.setItem("invite_code", inviteCode);
    }
  }, [inviteCode]);

  return <Navigate to="/auth/phone" replace />;
}
