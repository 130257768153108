import { useEffect, useMemo, useState } from "react";
import { AsyncAction, AsyncActionController } from "./async-action";
import { Result } from "../../domain/domain";

export function useAsyncActionListener<F, S>(props: {
  controller: AsyncActionController<F, S>;
  onSuccess?: (data: S) => void;
  onFailure?: (failure: F) => void;
}): void {
  useEffect(() => {
    let previousState = props.controller.state.value;

    const subscription = props.controller.state.subscribe({
      next: (state) => {
        if (previousState === state) {
          return;
        }

        previousState = state;

        if (state.result !== undefined) {
          state.result.fold({
            onSuccess: (data) => {
              if (props.onSuccess !== undefined) {
                props.onSuccess(data);
              }
            },
            onFailure: (failure) => {
              if (props.onFailure !== undefined) {
                props.onFailure(failure);
              }
            },
          });
        }
      },
    });

    return () => subscription.unsubscribe();
  }, [props.controller, props.onSuccess, props.onFailure]);
}

export function useAsyncActionState<F, S>(
  controller: AsyncActionController<F, S>
): AsyncAction<F, S> {
  const [state, setState] = useState<AsyncAction<F, S>>(controller.state.value);

  useEffect(() => {
    const subscription = controller.state.subscribe({
      next: (newState) => {
        if (state === newState) {
          return;
        }

        setState(newState);
      },
    });

    return () => subscription.unsubscribe();
  }, [controller]);

  return state;
}

export function useAsyncAction<F, S>(props: {
  action: (params: any) => Promise<Result<F, S>>;
  onSuccess?: (data: S) => void;
  onFailure?: (failure: F) => void;
}): [AsyncAction<F, S>, AsyncActionController<F, S>] {
  const actionController = useMemo(
    () => new AsyncActionController(props.action),
    // TODO: Check if it's needed
    // [props.action]
    []
  );

  const state = useAsyncActionState<F, S>(actionController);

  useAsyncActionListener<F, S>({
    controller: actionController,
    onSuccess: props.onSuccess,
    onFailure: props.onFailure,
  });

  return [state, actionController];
}
