import {
  Modal,
  ModalContent,
  ModalBody,
  ModalHeader,
  Link,
} from "@heroui/react";
import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Config, FontAwesomeSvgIcon } from "../../../core/core";
import { faTelegram } from "@fortawesome/free-brands-svg-icons";
import { AnalyticsEvents, logAnalyticsEvent } from "../../firebase/firebase";

export function useSupportModal(): {
  supportModal: React.ReactNode;
  openSupportModal: () => void;
} {
  const navigate = useNavigate();
  const location = useLocation();
  const isProfileOpen = location.hash === "#support";
  const onOpen = useCallback(() => {
    navigate(location.pathname + "#support");
  }, [location.pathname, navigate]);
  const onOpenChange = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        onOpen();
      } else {
        history.back();
      }
    },
    [onOpen]
  );

  return {
    openSupportModal: onOpen,
    supportModal: (
      <Modal
        isOpen={isProfileOpen}
        onOpenChange={onOpenChange}
        hideCloseButton={true}
        size="md"
        backdrop="blur"
        scrollBehavior="inside"
      >
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1 p-4">
            پشتیبانی
          </ModalHeader>
          <ModalBody className="px-4">
            <p>
              ما اینجا هستیم تا به شما کمک کنیم! برای ارتباط با تیم پشتیبانی،
              روی لینک زیر کلیک کنید:
            </p>
            <div className="flex flex-row justify-center items-center">
              <Link
                href={Config.telegramSupportUrl}
                isExternal
                className="mt-0.5"
                onPress={() => {
                  logAnalyticsEvent(AnalyticsEvents.support.linkClicked);
                }}
              >
                {Config.telegramSupportUrl}
              </Link>
              <FontAwesomeSvgIcon
                icon={faTelegram}
                size="xl"
                className="text-primary mr-2"
              />
            </div>
            <p>
              تیم پشتیبانی ما در کوتاه‌ترین زمان ممکن پاسخگوی شما خواهد بود. 🙌
            </p>
          </ModalBody>
        </ModalContent>
      </Modal>
    ),
  };
}
