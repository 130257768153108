import React, { useEffect } from "react";
import { useStore } from "../../../../core/core";
import { Battery } from "./Battery";

export const BatteryBadge: React.FC<{ onClicked: () => void }> = ({
  onClicked,
}) => {
  const batteryState = useStore((state) => state.batteryState);
  const getBatteryState = useStore((state) => state.getBatteryState);

  useEffect(() => {
    batteryState.maybeFold({
      orElse: getBatteryState,
      loadSuccess: () => {},
      loadInProgress: () => {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBatteryState]);

  return (
    <>
      {batteryState.maybeFold({
        orElse: () => <></>,
        loadSuccess: (state) => (
          <div onClick={onClicked} className="cursor-pointer">
            <Battery state={state} mini />
          </div>
        ),
      })}
    </>
  );
};
