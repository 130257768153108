import {
  EnvironmentVariables,
  socketClient,
  SocketConnectionState,
} from "../../../../core/core";
import { useSocketConnectionState } from "../../utils/useSocketConnectionState";

export function SocketReconnectionIndicator() {
  const socketConnectionState = useSocketConnectionState();

  if (EnvironmentVariables.isPreLaunch) {
    return <></>;
  }

  return (
    <div
      className="absolute left-4 flex flex-row items-center justify-end drop-shadow-xl bg-white rounded-full w-9 hover:w-[112px] transition-all duration-150"
      style={{
        bottom:
          socketConnectionState === SocketConnectionState.connected
            ? "-56px"
            : "16px",
      }}
    >
      <p
        className="text-sm text-primary-500 transition-[width] cursor-pointer max-h-5 line-clamp-1 absolute right-1 select-none"
        onClick={() => socketClient.reconnectManually()}
      >
        اتصال مجدد
      </p>
      <div className="bg-white z-10 rounded-full p-1">
        <div className="circular-loader" />
      </div>
    </div>
  );
}
