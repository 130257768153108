import { Button, Progress, Spinner } from "@heroui/react";
import {
  batteryRepository,
  FailureView,
  FontAwesomeSvgIcon,
  matchmakingRepository,
  showErrorToast,
  Timer,
  useAsyncAction,
  useStore,
  walletRepository,
} from "../../../core/core";
import {
  faBagShopping,
  faBolt,
  faCircleQuestion,
  faCrown,
  faRocket,
} from "@fortawesome/free-solid-svg-icons";
import { useCallback, useEffect, useMemo } from "react";
import { Battery } from "./components/Battery";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { MatchMakingState } from "../../match_making/match-making";
import { useBatteryGuideModal } from "./components/useBatteryGuideModal";

export const BatteryPage = () => {
  const navigate = useNavigate();

  const batteryState = useStore((state) => state.batteryState);
  const getBatteryState = useStore((state) => state.getBatteryState);
  const refreshBatteryState = useStore((state) => state.refreshBatteryState);

  const activeSubscription = useStore((state) => state.activeSubscription);
  const getActiveSubscription = useStore(
    (state) => state.getActiveSubscription
  );

  useEffect(() => {
    batteryState.maybeFold({
      orElse: getBatteryState,
      loadSuccess: () => {},
      loadInProgress: () => {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getBatteryState]);

  useEffect(() => {
    activeSubscription.maybeFold({
      orElse: getActiveSubscription,
      loadSuccess: () => {},
      loadInProgress: () => {},
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getActiveSubscription]);

  const [rechargeState, rechargeAction] = useAsyncAction({
    action: () => batteryRepository.recharge(),
    onFailure: showErrorToast,
    onSuccess: () => {
      refreshBatteryState();
      toast.success("باتری با موفقیت شارژ شد!");
    },
  });

  const [boostState, boostAction] = useAsyncAction({
    action: () => batteryRepository.boost(),
    onFailure: showErrorToast,
    onSuccess: () => {
      getBatteryState();
      toast.success("باتری با موفقیت شارژ شد!");
    },
  });

  const nextRechargeAt = useMemo(
    () =>
      batteryState.maybeFold({
        orElse: () => null,
        loadSuccess: (state) =>
          new Date(state.lastRechargedAt.getTime() + 24 * 60 * 60 * 1000),
      }),
    [batteryState]
  );
  const hasReachedRechargeTime = useMemo(
    () =>
      batteryState.maybeFold({
        orElse: () => false,
        loadSuccess: (state) => {
          const currentTime = new Date().getTime();
          const hoursDifference =
            (currentTime - state.lastRechargedAt.getTime()) / (1000 * 60 * 60);

          return hoursDifference >= 24;
        },
      }),
    [batteryState]
  );

  const updateWalletsState = useStore((state) => state.setWallets);

  const loadWallets = useCallback(async () => {
    const result = await walletRepository.getWallets();
    result.fold({
      onSuccess: (wallets) => {
        updateWalletsState(wallets);
      },
      onFailure: () => {},
    });
  }, [updateWalletsState]);

  useEffect(() => {
    loadWallets();
  }, [loadWallets]);

  useEffect(() => {
    const subscription = matchmakingRepository
      .watchMatchmakingState()
      .subscribe((matchMakingState: MatchMakingState) => {
        if (matchMakingState.state === "Matched") {
          // TODO:
          loadWallets();
        }
      });

    return () => subscription.unsubscribe();
  }, [loadWallets]);

  const walletsState = useStore((state) => state.wallets);

  const stars = Number(
    walletsState?.filter((wallet) => wallet.currency === "Star")[0].balance
  );

  const { batteryGuideModal, openBatteryGuideModal } = useBatteryGuideModal();

  return (
    <>
      {batteryState.maybeFold({
        orElse: () => <Spinner />,
        loadFailure: (failure) => (
          <FailureView failure={failure} onRetry={getBatteryState} />
        ),
        loadSuccess: (batteryState) =>
          activeSubscription.maybeFold({
            orElse: () => <Spinner />,
            loadFailure: (failure) => (
              <FailureView failure={failure} onRetry={getActiveSubscription} />
            ),
            loadSuccess: (activeSubscription) => {
              return (
                <>
                  <div className="flex flex-row justify-between items-center">
                    <h1 className="font-bold text-lg">باتری من</h1>

                    <Button
                      variant="light"
                      onPress={openBatteryGuideModal}
                      startContent={
                        <FontAwesomeSvgIcon icon={faCircleQuestion} size="2x" />
                      }
                      isIconOnly
                      className="rounded-full"
                    />
                  </div>

                  <Battery
                    state={batteryState}
                    multiplier={activeSubscription.multiplier}
                  />

                  <div className="flex flex-row items-center justify-around py-6">
                    <div
                      className={
                        "border-2 rounded-full w-32 h-32 flex flex-row items-center justify-center cursor-pointer " +
                        (hasReachedRechargeTime &&
                        batteryState.capacity !== batteryState.charge
                          ? "border-primary"
                          : "")
                      }
                      onClick={() => {
                        if (hasReachedRechargeTime) {
                          if (batteryState.capacity !== batteryState.charge) {
                            rechargeAction.invoke(null);
                          } else {
                            toast.info("باتری پر است!");
                          }
                        }
                      }}
                    >
                      {rechargeState.isLoading ? (
                        <Spinner />
                      ) : hasReachedRechargeTime ? (
                        <FontAwesomeSvgIcon
                          icon={faBolt}
                          size="3x"
                          className={
                            hasReachedRechargeTime &&
                            batteryState.capacity !== batteryState.charge
                              ? "text-primary"
                              : "text-gray-500"
                          }
                        />
                      ) : (
                        <div className="flex flex-col justify-center items-center">
                          <div className="text-lg font-medium">
                            <Timer end={nextRechargeAt!} />
                          </div>
                          <p className="text-sm">تا شارژ دوباره</p>
                        </div>
                      )}
                    </div>

                    {/*
                      - Show timer inside, also we can show a progress bar for timer around the button
                    */}
                    <div
                      className={
                        "relative border-2 rounded-full w-32 h-32 flex flex-row items-center justify-center cursor-pointer " +
                        (stars !== 0 ? "border-danger" : "")
                      }
                      onClick={() => {
                        if (stars === 0) {
                          // TODO: Show a dialog and tell them how to get stars
                          toast.info("استار کافی ندارید!");
                          return;
                        }
                        if (batteryState.capacity !== batteryState.charge) {
                          boostAction.invoke(null);
                        } else {
                          toast.info("باتری پر است!");
                        }
                      }}
                    >
                      {boostState.isLoading ? (
                        <Spinner />
                      ) : (
                        <FontAwesomeSvgIcon
                          icon={faRocket}
                          size="3x"
                          className={
                            stars !== 0 ? "text-danger" : "text-gray-500"
                          }
                        />
                      )}
                    </div>
                  </div>

                  {activeSubscription.id ? (
                    <>
                      <div className="flex flex-row justify-between items-center">
                        <span className="flex flex-row-reverse items-center justify-center gap-1">
                          <h1>باقی مانده زمان اشتراک</h1>
                          <FontAwesomeSvgIcon
                            icon={faCrown}
                            size="xl"
                            className="text-yellow-500"
                          />
                        </span>
                        <Timer end={activeSubscription.expiresAt!} />
                      </div>
                      <Progress
                        aria-label="remaining-subscription"
                        className="max-w"
                        maxValue={activeSubscription.duration}
                        value={
                          (activeSubscription.expiresAt!.getTime() -
                            new Date().getTime()) /
                          1000 /
                          86400
                        }
                      />
                    </>
                  ) : (
                    <Button
                      variant="solid"
                      color="primary"
                      onPress={() => {
                        navigate(location.pathname + "#plans");
                      }}
                      startContent={<FontAwesomeSvgIcon icon={faBagShopping} />}
                    >
                      خرید اشتراک
                    </Button>
                  )}
                </>
              );
            },
          }),
      })}

      {batteryGuideModal}
    </>
  );
};

// - Update battery when user finds a match
