import {
  DomainFailure,
  nothing,
  Nothing,
  RemoteDataSource,
  Result,
} from "../../../core/core";
import {
  Feedback,
  SkipFeedbackRequest,
  SubmitFeedbackRequest,
} from "./entities/entities";

export class FeedbackRemoteDataSource extends RemoteDataSource {
  async get(label: string): Promise<Result<DomainFailure, Feedback>> {
    const result = await this.httpClient.get(`/feedback/${label}`);

    return result.map((data) => data as Feedback);
  }

  async canSubmitAnswer(id: string): Promise<Result<DomainFailure, boolean>> {
    const result = await this.httpClient.get(`/feedback/can-submit/${id}`);

    return result.map((data) => data["canSubmitAnswer"] as boolean);
  }

  async submit(
    request: SubmitFeedbackRequest
  ): Promise<Result<DomainFailure, Nothing>> {
    const result = await this.httpClient.post("/feedback/submit", {
      body: request,
    });

    return result.map(() => nothing);
  }

  async skip(
    request: SkipFeedbackRequest
  ): Promise<Result<DomainFailure, Nothing>> {
    const result = await this.httpClient.post("/feedback/skip", {
      body: request,
    });

    return result.map(() => nothing);
  }
}
