export class CacheManager {
  set(key: string, value: any): void {
    localStorage.setItem(key, JSON.stringify(value));
  }

  get<T>(key: string): T | undefined {
    const value = localStorage.getItem(key);
    if (!value) {
      return undefined;
    }
    return JSON.parse(value) as T;
  }

  remove(key: string): void {
    localStorage.removeItem(key);
  }
}
