import { useRouteError } from "react-router-dom";
import { DomainFailure } from "../../domain/domain";
import { Button } from "@heroui/react";
import WifiOff from "../../../assets/icons/wifi_off.svg";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeSvgIcon } from "../../core";

export function FailureView(props: {
  failure: DomainFailure;
  onRetry: () => void;
}) {
  return props.failure.fold({
    onNetworkFailure: () => <NetworkFailureView onRetry={props.onRetry} />,
    onServerFailure: () => <ServerFailureView onRetry={props.onRetry} />,
  });
}

export function RouterFailureView() {
  const error = useRouteError();

  if (error instanceof DomainFailure) {
    return error.fold({
      onNetworkFailure: () => (
        <NetworkFailureView onRetry={() => location.reload()} />
      ),
      onServerFailure: () => (
        <ServerFailureView onRetry={() => location.reload()} />
      ),
    });
  }

  return <ServerFailureView onRetry={() => location.reload()} />;
}

function NetworkFailureView(props: { onRetry: () => void }) {
  return (
    <div className="flex flex-col h-screen justify-center items-center">
      <img src={WifiOff} alt="Wifi Off Icon" width={80} />
      <div className="h-4" />
      <p>ارتباط اینترنت خود را بررسی و مجدد امتحان کنید.</p>
      <div className="h-4" />
      <Button color="primary" variant="solid" size="lg" onPress={props.onRetry}>
        تلاش مجدد
      </Button>
    </div>
  );
}

function ServerFailureView(props: { onRetry: () => void }) {
  return (
    <div className="flex flex-col h-screen justify-center items-center">
      <FontAwesomeSvgIcon icon={faTriangleExclamation} fontSize={80} />
      <div className="h-4" />
      <p>مشکلی رخ داده است. لطفا مجدد تلاش کنید.</p>
      <div className="h-4" />
      <Button color="primary" variant="solid" size="lg" onPress={props.onRetry}>
        تلاش مجدد
      </Button>
    </div>
  );
}
