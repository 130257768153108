import { DomainFailure, RemoteDataSource, Result } from "../../../core/core";
import { MatchMakingState } from "./domain";

export class MatchmakingRemoteDataSource extends RemoteDataSource {
  public async getState(): Promise<Result<DomainFailure, MatchMakingState>> {
    const result = await this.httpClient.get("/matchmaking/current-state");

    return result.map((data) => data as MatchMakingState);
  }
}
