import { create } from "zustand";
import { Profile } from "../features/profile/profile";
import { Wallet } from "../features/wallet/wallet";
import { ChatRoomSummary } from "../features/chat/domain/domain";
import {
  AsyncValue,
  batteryRepository,
  DomainFailure,
  paymentRepository,
} from "./core";
import { setAnalyticsUserId } from "../features/firebase/firebase";
import { BatteryState } from "../features/battery/battery";
import { ActiveSubscription } from "../features/payment/domain/domain";

type State = {
  profile?: Profile;
  wallets?: Wallet[];
  batteryState: AsyncValue<DomainFailure, BatteryState>;
  activeSubscription: AsyncValue<DomainFailure, ActiveSubscription>;
  rooms: AsyncValue<DomainFailure, ChatRoomSummary[]>;
};

type Actions = {
  setProfile: (profile: State["profile"]) => void;
  setWallets: (wallet: State["wallets"]) => void;
  setRooms: (rooms: AsyncValue<DomainFailure, ChatRoomSummary[]>) => void;
  resetState: () => void;
  getBatteryState: () => void;
  refreshBatteryState: () => void;
  getActiveSubscription: () => void;
};

export const useStore = create<State & Actions>((set) => ({
  profile: undefined,
  wallets: undefined,
  batteryState: AsyncValue.initial<DomainFailure, BatteryState>(),
  activeSubscription: AsyncValue.initial<DomainFailure, ActiveSubscription>(),
  rooms: AsyncValue.initial<DomainFailure, ChatRoomSummary[]>(),
  setProfile: (profile) => {
    setAnalyticsUserId(profile?.id ?? null);
    set(() => ({ profile }));
  },
  setWallets: (wallets) => set(() => ({ wallets })),
  setRooms: (rooms) => set(() => ({ rooms })),
  resetState: () =>
    set({
      wallets: undefined,
      profile: undefined,
      rooms: AsyncValue.initial<DomainFailure, ChatRoomSummary[]>(),
    }),
  getBatteryState: async () => {
    set(() => ({
      batteryState: AsyncValue.loadInProgress<DomainFailure, BatteryState>(),
    }));

    const result = await batteryRepository.get();

    set(() => ({
      batteryState: result.fold<AsyncValue<DomainFailure, BatteryState>>({
        onSuccess: (data) =>
          AsyncValue.loadSuccess<DomainFailure, BatteryState>(data),
        onFailure: (failure) =>
          AsyncValue.loadFailure<DomainFailure, BatteryState>(failure),
      }),
    }));
  },
  refreshBatteryState: async () => {
    const result = await batteryRepository.get();

    result.fold({
      onSuccess: (data) => {
        set(() => ({
          batteryState: AsyncValue.loadSuccess<DomainFailure, BatteryState>(
            data
          ),
        }));
      },
      onFailure: () => {},
    });
  },
  getActiveSubscription: async () => {
    set(() => ({
      activeSubscription: AsyncValue.loadInProgress<
        DomainFailure,
        ActiveSubscription
      >(),
    }));

    const result = await paymentRepository.getActiveSubscription();

    set(() => ({
      activeSubscription: result.fold<
        AsyncValue<DomainFailure, ActiveSubscription>
      >({
        onSuccess: (data) =>
          AsyncValue.loadSuccess<DomainFailure, ActiveSubscription>(data),
        onFailure: (failure) =>
          AsyncValue.loadFailure<DomainFailure, ActiveSubscription>(failure),
      }),
    }));
  },
}));
