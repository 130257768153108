import { DomainFailure, Result } from "../../../core/core";
import { WalletRemoteDataSource } from "./wallet-remote-data-source";
import { Wallet } from "./entities/entities";

export class WalletRepository {
  constructor(private readonly _remoteDataSource: WalletRemoteDataSource) {}

  getWallets(): Promise<Result<DomainFailure, Wallet[]>> {
    return this._remoteDataSource.getWallets();
  }
}
