import { Observable } from "rxjs";
import { DomainFailure, Nothing, Result } from "../../../core/core";
import { StartMatchmakingRequest, MatchMakingState } from "./entities/entities";
import { MatchmakingSocketDataSource } from "./match-making-socket-data-source";
import { MatchmakingRemoteDataSource } from "./match-making-remote-data-source";
import { MatchmakingLocaleDataSource } from "./match-making-local-data-source";

export class MatchmakingRepository {
  constructor(
    private readonly _socketDataSource: MatchmakingSocketDataSource,
    private readonly _remoteDataSource: MatchmakingRemoteDataSource,
    private readonly _localDataSource: MatchmakingLocaleDataSource
  ) {}

  public async getState(): Promise<Result<DomainFailure, MatchMakingState>> {
    const result = await this._remoteDataSource.getState();

    return result.map((data) => data as MatchMakingState);
  }

  startMatchmaking(
    params: StartMatchmakingRequest
  ): Promise<Result<DomainFailure, Nothing>> {
    return this._socketDataSource.startMatchmaking(params);
  }

  // Can cancel only when state is queued
  cancelMatchmaking(): Promise<Result<DomainFailure, Nothing>> {
    return this._socketDataSource.cancelMatchmaking();
  }

  watchMatchmakingState(): Observable<MatchMakingState> {
    return this._socketDataSource.watchMatchmakingState();
  }

  updateLastSuccessMatchDate(date: Date) {
    this._localDataSource.setLastSuccessMatchDate(date);
  }

  private static coolDownDurationInMs = 30_000;

  isCoolDownOver(): boolean {
    const lastMatchDate = this._localDataSource.getLastSuccessMatchDate();
    if (!lastMatchDate) return true;
    return (
      new Date().getTime() - lastMatchDate.getTime() >
      MatchmakingRepository.coolDownDurationInMs
    );
  }

  getLastSuccessMatchDate(): Date | undefined {
    return this._localDataSource.getLastSuccessMatchDate();
  }

  getCoolDownEndInSeconds(): number | null {
    const lastMatchDate = this._localDataSource.getLastSuccessMatchDate();
    if (!lastMatchDate) {
      return null;
    }

    return Math.floor(
      (lastMatchDate.getTime() -
        new Date().getTime() +
        MatchmakingRepository.coolDownDurationInMs) /
        1_000
    );
  }
}
