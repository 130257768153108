import { BehaviorSubject } from "rxjs";
import { Result } from "../../domain/domain";

type AsyncValueFoldParams<F, S, R> = {
  initial: () => R;
  loadInProgress: () => R;
  loadSuccess: (data: S) => R;
  loadFailure: (failure: F) => R;
};

type AsyncValueMaybeFoldParams<F, S, R> = {
  initial?: () => R;
  loadInProgress?: () => R;
  loadSuccess?: (data: S) => R;
  loadFailure?: (failure: F) => R;
  orElse: () => R;
};

abstract class AsyncValue<F, S> {
  abstract fold<R>(params: AsyncValueFoldParams<F, S, R>): R;

  maybeFold<R>(params: AsyncValueMaybeFoldParams<F, S, R>): R {
    return this.fold({
      initial: () => (params.initial ? params.initial!() : params.orElse()),
      loadInProgress: () =>
        params.loadInProgress ? params.loadInProgress!() : params.orElse(),
      loadFailure: (failure) =>
        params.loadFailure ? params.loadFailure!(failure) : params.orElse(),
      loadSuccess: (data) =>
        params.loadSuccess ? params.loadSuccess!(data) : params.orElse(),
    });
  }

  static initial<F, S>(): AsyncValue<F, S> {
    return new AsyncValueInitialState<F, S>();
  }

  static loadInProgress<F, S>(): AsyncValue<F, S> {
    return new AsyncValueLoadInProgressState<F, S>();
  }

  static loadSuccess<F, S>(data: S): AsyncValue<F, S> {
    return new AsyncValueLoadSuccessState<F, S>(data);
  }

  static loadFailure<F, S>(failure: F): AsyncValue<F, S> {
    return new AsyncValueLoadFailureState<F, S>(failure);
  }
}

class AsyncValueInitialState<F, S> extends AsyncValue<F, S> {
  override fold<R>(params: AsyncValueFoldParams<F, S, R>): R {
    return params.initial();
  }

  override toString() {
    return "AsyncValueInitialState()";
  }
}

class AsyncValueLoadInProgressState<F, S> extends AsyncValue<F, S> {
  override fold<R>(params: AsyncValueFoldParams<F, S, R>): R {
    return params.loadInProgress();
  }

  override toString() {
    return "AsyncValueLoadInProgressState()";
  }
}

class AsyncValueLoadSuccessState<F, S> extends AsyncValue<F, S> {
  constructor(readonly data: S) {
    super();
  }

  override fold<R>(params: AsyncValueFoldParams<F, S, R>): R {
    return params.loadSuccess(this.data);
  }

  override toString() {
    return `AsyncValueLoadSuccessState(${this.data})`;
  }
}

class AsyncValueLoadFailureState<F, S> extends AsyncValue<F, S> {
  constructor(readonly failure: F) {
    super();
  }

  override fold<R>(params: AsyncValueFoldParams<F, S, R>): R {
    return params.loadFailure(this.failure);
  }

  override toString() {
    return `AsyncValueLoadFailureState(${this.failure})`;
  }
}

class AsyncValueController<F, S> {
  private _state: BehaviorSubject<AsyncValue<F, S>>;

  public get state() {
    return this._state;
  }

  constructor(
    private readonly action: (params: unknown) => Promise<Result<F, S>>
  ) {
    this._state = new BehaviorSubject<AsyncValue<F, S>>(
      new AsyncValueInitialState<F, S>()
    );
  }

  public async invoke(params: unknown): Promise<void> {
    this._state.next(new AsyncValueLoadInProgressState<F, S>());

    const result = await this.action(params);

    this._state.next(
      result.fold<AsyncValue<F, S>>({
        onSuccess: (data) => new AsyncValueLoadSuccessState<F, S>(data),
        onFailure: (failure) => new AsyncValueLoadFailureState<F, S>(failure),
      })
    );
  }
}

export { AsyncValue, AsyncValueController };
