import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@heroui/react";
import { useCallback, useMemo } from "react";
import { faCopy } from "@fortawesome/free-regular-svg-icons";
import {
  copyTextToClipboard,
  FontAwesomeSvgIcon,
  toPersianPrice,
} from "../../../../../core/core";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  AnalyticsEvents,
  logAnalyticsEvent,
} from "../../../../firebase/firebase";

export function PaymentSuccess(props: { price: number; orderId: string }) {
  const navigate = useNavigate();

  const { orderId, price } = props;

  const priceString = useMemo(() => toPersianPrice(price.toString()), [price]);

  const copyFollowUpCode = useCallback(() => {
    logAnalyticsEvent(AnalyticsEvents.paymentResult.copyOrderIdClicked, {
      order_id: orderId,
    });

    copyTextToClipboard(orderId);

    toast.info("کد پیگیری کپی شد.");
  }, [orderId]);

  return (
    <div className="flex flex-col justify-center h-dvh md:h-auto p-4">
      <div className="flex-grow flex flex-col justify-center items-center">
        <FontAwesomeSvgIcon
          icon={faCheckCircle}
          className="text-green-500"
          size="9x"
        />
        <h1 className="text-xl font-medium mt-6 text-center">
          پرداخت با موفقیت انجام شد!
        </h1>
        <h2 className="text-center mt-4">
          اشتراک شما با موفقیت فعال شد.
          <br />
          ممنون که از فندق استفاده می‌کنید!
        </h2>
      </div>

      <div className="py-3">
        <div className="flex flex-row justify-between py-2">
          <h1 className="font-medium">مبلغ پرداختی:</h1>
          <h2>{priceString} تومان</h2>
        </div>
        <div className="flex flex-col items-stretch gap-2 py-1">
          <h1 className="font-medium">کد پیگیری:</h1>
          <div className="flex flex-row justify-between py-1 px-2 items-center bg-gray-100 rounded-[8px] ">
            <Button
              isIconOnly
              size="sm"
              variant="light"
              className="ml-2"
              onPress={copyFollowUpCode}
            >
              <FontAwesomeSvgIcon icon={faCopy} size="xl" />
            </Button>
            <h2
              className="font-mono text-xs line-clamp-1 text-left"
              style={{ direction: "ltr" }}
            >
              {orderId}
            </h2>
          </div>
        </div>
      </div>

      <Button
        variant="bordered"
        color="primary"
        onPress={() => {
          logAnalyticsEvent(
            AnalyticsEvents.paymentResult.inviteFriendsButtonClicked
          );
          navigate("#invite");
        }}
      >
        دعوت دوستان و کسب توکن بیشتر!
      </Button>
      <span className="h-3" />
      <Button
        variant="solid"
        color="primary"
        onPress={() => {
          logAnalyticsEvent(
            AnalyticsEvents.paymentResult.successReturnToHomeButtonClicked
          );
          navigate("/");
        }}
      >
        بازگشت به صفحه اصلی
      </Button>
    </div>
  );
}
