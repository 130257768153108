import { DomainFailure, RemoteDataSource, Result } from "../../../core/core";
import { ReferralCodeStatistic } from "./entities/ReferralCodeStatistic";

export class ReferralRemoteDataSource extends RemoteDataSource {
  async getMyCode(): Promise<Result<DomainFailure, string>> {
    const result = await this.httpClient.get("/invite-code");

    return result.map((data) => data["code"] as string);
  }

  async getMyCodeStatistic(): Promise<
    Result<DomainFailure, ReferralCodeStatistic>
  > {
    const result = await this.httpClient.get("/invite-code/statistic");

    return result.map((data) => data as ReferralCodeStatistic);
  }
}
