import { AxiosInstance, AxiosProgressEvent } from "axios";
import { DomainFailure, Result } from "../../domain/domain";
import { Json } from "./json";

export type HttpClientResponse = Result<DomainFailure, Json>;

export class HttpClient {
  constructor(private readonly axios: AxiosInstance) {}

  private async performRequest(params: {
    method: string;
    path: string;
    headers?: Json;
    body?: Json;
    queryParameters?: Json;
    onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
    onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void;
    cancelToken?: CancelToken;
  }): Promise<HttpClientResponse> {
    const {
      path,
      method,
      body,
      queryParameters,
      headers,
      onUploadProgress,
      onDownloadProgress,
      cancelToken,
    } = params;

    try {
      const result = await this.axios.request({
        url: path,
        method,
        data: body,
        params: queryParameters,
        headers: headers,
        onUploadProgress,
        onDownloadProgress,
        signal: cancelToken?.signal,
      });

      return Result.success(result.data);
    } catch (error: any) {
      if (error.response) {
        return Result.failure(
          DomainFailure.serverFailure({
            statusCode: error.response.status,
            errorCode: error.response.data?.meta?.error,
          })
        );
      }
      return Result.failure(DomainFailure.networkFailure());
    }
  }

  public get(
    path: string,
    params?: {
      queryParameters?: Json;
      headers?: Json;
      cancelToken?: CancelToken;
    }
  ): Promise<HttpClientResponse> {
    return this.performRequest({
      method: "GET",
      path,
      headers: params?.headers,
      queryParameters: params?.queryParameters,
      cancelToken: params?.cancelToken,
    });
  }

  public post(
    path: string,
    params?: {
      headers?: Json;
      body?: Json;
      queryParameters?: Json;
      cancelToken?: CancelToken;
    }
  ): Promise<HttpClientResponse> {
    return this.performRequest({
      method: "POST",
      path,
      headers: params?.headers,
      body: params?.body,
      queryParameters: params?.queryParameters,
      cancelToken: params?.cancelToken,
    });
  }

  public put(
    path: string,
    params?: {
      headers?: Json;
      body?: Json;
      queryParameters?: Json;
      cancelToken?: CancelToken;
    }
  ): Promise<HttpClientResponse> {
    return this.performRequest({
      method: "PUT",
      path,
      headers: params?.headers,
      body: params?.body,
      queryParameters: params?.queryParameters,
      cancelToken: params?.cancelToken,
    });
  }

  public patch(
    path: string,
    params?: {
      headers?: Json;
      body?: Json;
      queryParameters?: Json;
      cancelToken?: CancelToken;
    }
  ): Promise<HttpClientResponse> {
    return this.performRequest({
      method: "PATCH",
      path,
      headers: params?.headers,
      body: params?.body,
      queryParameters: params?.queryParameters,
      cancelToken: params?.cancelToken,
    });
  }

  public delete(
    path: string,
    params?: {
      headers?: Json;
      body?: Json;
      queryParameters?: Json;
      cancelToken?: CancelToken;
    }
  ): Promise<HttpClientResponse> {
    return this.performRequest({
      method: "DELETE",
      path,
      headers: params?.headers,
      body: params?.body,
      queryParameters: params?.queryParameters,
      cancelToken: params?.cancelToken,
    });
  }
}

class CancelToken {
  private readonly abortController = new AbortController();

  public get signal() {
    return this.abortController.signal;
  }

  public isCancelled(): boolean {
    return this.abortController.signal.aborted;
  }

  public cancel(reason?: unknown): void {
    this.abortController.abort(reason);
  }
}
