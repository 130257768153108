import { ReactNode, useCallback, useEffect, useState } from "react";
import { Spacer } from "@heroui/react";
import AuthBg from "/auth_bg.jpeg";
import Logo from "/logo.png";

export default function AuthWrapper(props: { children: ReactNode }) {
  const [height, setHeight] = useState(window.innerHeight);
  const setHeightCallback = useCallback(
    () => setHeight(window.innerHeight),
    []
  );

  useEffect(() => {
    visualViewport?.addEventListener("resize", setHeightCallback);
    visualViewport?.addEventListener("scroll", setHeightCallback);

    return () => {
      visualViewport?.removeEventListener("resize", setHeightCallback);
      visualViewport?.removeEventListener("scroll", setHeightCallback);
    };
  }, [setHeightCallback]);

  return (
    <div className="flex flex-row">
      <div className="w-1/2 hidden md:block">
        <img src={AuthBg} className="h-screen w-auto object-cover" alt="" />
      </div>
      <div
        className="w-screen md:w-1/2 flex flex-col justify-center items-center box-border px-4 transition-height delay-75"
        style={{
          height,
        }}
      >
        <div className="flex items-center">
          <img src={Logo} alt="Fandogh Logo" width={100} />
          <h1 className="p-4 text-center" style={{ fontSize: 64 }}>
            فندق
          </h1>
        </div>
        <Spacer />
        {props.children}
      </div>
    </div>
  );
}
