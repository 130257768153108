const persianDigits = "۰۱۲۳۴۵۶۷۸۹";

export const toPersianDigits = (text: string) => {
  return text.replace(/\d/g, (d) => persianDigits[Number(d)]);
};

export const toPersianPrice = (text: string) => {
  return text
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    .replace(/\d/g, (d) => persianDigits[Number(d)]);
};
