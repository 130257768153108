import { useCallback, useEffect, useRef } from "react";
import { usePushNotificationPermissionModal } from "./usePushNotificationPermissionModal";
import { firebaseRepository } from "../../../core/core";

export function useFcmInit(): {
  pushNotificationPermissionModal: React.ReactNode;
} {
  const sendFcmTokenSubmissionTryCount = useRef(1);

  const sendFcmToken = useCallback(async () => {
    if (sendFcmTokenSubmissionTryCount.current > 3) {
      return;
    }
    sendFcmTokenSubmissionTryCount.current++;

    const result = await firebaseRepository.sendToken();

    result.fold({
      onSuccess: () => {},
      onFailure: () => {
        sendFcmToken();
      },
    });
  }, []);

  const requestNotificationPermission = useCallback(async () => {
    try {
      const result = await Notification.requestPermission();

      if (result === "granted") {
        sendFcmToken();
      }
    } catch (error) {
      console.error(error);
    }
  }, [sendFcmToken]);

  const {
    openPushNotificationPermissionModal,
    pushNotificationPermissionModal,
  } = usePushNotificationPermissionModal({
    onConfirmPressed: requestNotificationPermission,
  });

  useEffect(() => {
    if (Notification.permission === "granted") {
      sendFcmToken();
    } else if (firebaseRepository.shouldRequestNotificationPermission()) {
      openPushNotificationPermissionModal();
    }
  }, [openPushNotificationPermissionModal, sendFcmToken]);

  return {
    pushNotificationPermissionModal,
  };
}
