import { Modal, ModalContent, ModalBody } from "@heroui/react";
import { lazy, Suspense, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { SuspenseLoading } from "../../../core/core";

// eslint-disable-next-line react-refresh/only-export-components
const Referral = lazy(() =>
  import("./Referral").then((module) => ({ default: module.Referral }))
);

export function useReferralModal(): {
  referralModal: React.ReactNode;
  openReferralModal: () => void;
} {
  const navigate = useNavigate();
  const location = useLocation();
  const isProfileOpen = location.hash === "#invite";
  const onOpen = useCallback(() => {
    navigate(location.pathname + "#invite");
  }, [location.pathname, navigate]);
  const onOpenChange = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        onOpen();
      } else {
        history.back();
      }
    },
    [onOpen]
  );

  return {
    referralModal: (
      <Modal
        isOpen={isProfileOpen}
        onOpenChange={onOpenChange}
        hideCloseButton={true}
        size="md"
        backdrop="blur"
        scrollBehavior="inside"
      >
        <ModalContent>
          <ModalBody className="p-4">
            <Suspense fallback={<SuspenseLoading />}>
              <Referral />
            </Suspense>
          </ModalBody>
        </ModalContent>
      </Modal>
    ),
    openReferralModal: onOpen,
  };
}
