import { toast } from "react-toastify";

export function showRegisterError(errorCode: string) {
  if (errorCode === "MAX_OTP_ATTEMPTS") {
    toast.error(
      "تعداد درخواست‌های ورود شما از حد مجاز عبور کرده است. لطفاً کمی صبر کنید و مجدداً امتحان کنید."
    );
  } else {
    toast.error("مشکلی رخ داده است. لطفا مجدد تلاش کنید.");
  }
}
