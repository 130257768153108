import { ReactNode, useCallback } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from "@heroui/react";
import {
  authRepository,
  showErrorToast,
  socketClient,
  useAsyncAction,
  useStore,
} from "../../../core/core";
import { useLocation, useNavigate } from "react-router-dom";
import { AnalyticsEvents, logAnalyticsEvent } from "../../firebase/firebase";

export function useLogOut(): [ReactNode, () => void] {
  const navigate = useNavigate();
  const location = useLocation();
  const isLogOutOpen = location.hash === "#log-out";
  const onOpen = useCallback(
    () => navigate(location.pathname + "#log-out"),
    [location.pathname, navigate]
  );
  const onOpenChange = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        onOpen();
      } else {
        history.back();
      }
    },
    [onOpen]
  );

  const [submitState, submitAction] = useAsyncAction({
    action: () => authRepository.logOut(),
    onFailure: showErrorToast,
    onSuccess: () => {
      navigate("/auth/join");
      socketClient.close();
      setTimeout(() => useStore.getState().resetState(), 500);
    },
  });

  return [
    <Modal
      isOpen={isLogOutOpen}
      onOpenChange={onOpenChange}
      isDismissable={!submitState.isLoading}
      isKeyboardDismissDisabled={submitState.isLoading}
      hideCloseButton={true}
      size="md"
    >
      <ModalContent>
        {(onClose) => (
          <>
            <ModalHeader className="flex flex-col gap-1 p-4">
              خروج از حساب کاربری
            </ModalHeader>
            <ModalBody className="px-4">
              <p>آیا میخواهید از حساب کاربری خارج شوید؟</p>
            </ModalBody>
            <ModalFooter className="p-4">
              <Button
                color="primary"
                variant="bordered"
                isDisabled={submitState.isLoading}
                onPress={() => {
                  logAnalyticsEvent(
                    AnalyticsEvents.logOutDialog.cancelButtonClicked
                  );
                  onClose();
                }}
                fullWidth
              >
                انصراف
              </Button>
              <Button
                color="danger"
                variant="solid"
                isLoading={submitState.isLoading}
                onPress={() => {
                  logAnalyticsEvent(
                    AnalyticsEvents.logOutDialog.confirmButtonClicked
                  );
                  submitAction.invoke({});
                }}
                fullWidth
              >
                خارج شدن
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>,
    onOpen,
  ];
}
