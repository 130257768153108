import { lazy } from "react";

const DotLottieReact = lazy(() =>
  import("@lottiefiles/dotlottie-react").then((module) => ({
    default: module.DotLottieReact,
  }))
);

export function MatchFoundConfetti() {
  const horizontalSpace = window.innerWidth / 2;
  const isMobile = window.innerWidth < 640;
  const top = isMobile ? undefined : window.innerHeight / 4;
  const bottom = isMobile ? 0 : undefined;

  return (
    <>
      <div
        className="absolute pointer-events-none"
        style={{
          zIndex: 999,
          top,
          bottom,
          left: horizontalSpace,
        }}
      >
        <DotLottieReact
          src="./lottie/match_found_confetti.lottie"
          autoplay
          width={100}
        />
      </div>
      <div
        className="absolute pointer-events-none"
        style={{
          zIndex: 999,
          top,
          bottom,
          right: horizontalSpace,
          transform: "scaleX(-1)",
        }}
      >
        <DotLottieReact
          src="./lottie/match_found_confetti.lottie"
          autoplay
          width={100}
        />
      </div>
    </>
  );
}
