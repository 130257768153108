import { DomainFailure, Result } from "../../../core/core";
import {
  ActiveSubscription,
  CreatePaymentRequest,
  Discount,
  Order,
  PaymentResultSummary,
  SubscriptionPlan,
} from "./entities/entities";
import { PaymentRemoteDataSource } from "./payment-remote-data-source";

export class PaymentRepository {
  constructor(private readonly _remoteDataSource: PaymentRemoteDataSource) {}

  async getSubscriptions(): Promise<Result<DomainFailure, SubscriptionPlan[]>> {
    const result = await this._remoteDataSource.getSubscriptions();

    return result.map((data) =>
      // Prices to IRT
      data.map((plan) => ({ ...plan, price: plan.price / 10 }))
    );
  }

  getSubscriptionHistory(): Promise<Result<DomainFailure, Order[]>> {
    return this._remoteDataSource.getSubscriptionHistory();
  }

  getActiveSubscription(): Promise<Result<DomainFailure, ActiveSubscription>> {
    return this._remoteDataSource.getActiveSubscription();
  }

  searchDiscount(code: string): Promise<Result<DomainFailure, Discount>> {
    return this._remoteDataSource.searchDiscount(code);
  }

  // Returns the payment url
  createPayment(
    request: CreatePaymentRequest
  ): Promise<Result<DomainFailure, string>> {
    return this._remoteDataSource.createPayment(request);
  }

  getPurchasedPlanSummary(
    paymentId: string
  ): Promise<Result<DomainFailure, PaymentResultSummary>> {
    return this._remoteDataSource.getPurchasedPlanSummary(paymentId);
  }
}
