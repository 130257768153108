import {
  Avatar,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  useDisclosure,
} from "@heroui/react";
import {
  FontAwesomeSvgIcon,
  profileRepository,
  showErrorToast,
  useAsyncAction,
  useStore,
} from "../../../core/core";
import { UpdateProfileRequest } from "../profile";
import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { AnalyticsEvents, logAnalyticsEvent } from "../../firebase/firebase";

export function useProfileModal() {
  const navigate = useNavigate();
  const location = useLocation();
  const isProfileOpen = location.hash === "#profile";
  const onOpen = useCallback(() => {
    navigate(location.pathname + "#profile");
  }, [location.pathname, navigate]);
  const onOpenChange = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        onOpen();
      } else {
        history.back();
      }
    },
    [onOpen]
  );

  return {
    profileModal: (
      <Modal
        isOpen={isProfileOpen}
        onOpenChange={onOpenChange}
        hideCloseButton={true}
        size="md"
        backdrop="blur"
      >
        <ModalContent>
          <ModalBody className="p-4">
            <ProfileView />
          </ModalBody>
        </ModalContent>
      </Modal>
    ),
    openProfileModal: onOpen,
  };
}

export function ProfileView() {
  const currentProfile = useStore((state) => state.profile)!;
  const [state, setState] = useState<UpdateProfileRequest>({
    fname: currentProfile.fname,
    lname: currentProfile.lname ?? "",
    nickname: currentProfile.nickname ?? "",
    avatar: currentProfile.avatar ?? null,
  });
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  const [submitState, submitAction] = useAsyncAction({
    action: (params: UpdateProfileRequest) =>
      profileRepository.update({
        fname: params.fname,
        lname: params.lname?.length === 0 ? null : params.lname,
        nickname: params.nickname?.length === 0 ? null : params.nickname,
        avatar: params.avatar ?? null,
      }),
    onFailure: showErrorToast,
    onSuccess: (newProfile) => {
      useStore.getState().setProfile({
        ...currentProfile,
        ...newProfile,
      });
      toast.success("پروفایل شما با موفقیت آپدیت شد.");
    },
  });

  return (
    <>
      <div className="flex flex-row justify-center relative">
        {state.avatar && (
          <Button
            isIconOnly
            color="danger"
            radius="full"
            size="sm"
            className="absolute bottom-0 z-[1] mr-20"
            startContent={<FontAwesomeSvgIcon icon={faTrashAlt} />}
            onPress={() => {
              logAnalyticsEvent(AnalyticsEvents.profile.avatarRemoved);
              setState({ ...state, avatar: null });
            }}
          />
        )}
        <Avatar
          className="w-24 h-24 cursor-pointer"
          src={state.avatar ? "/avatars/" + state.avatar : undefined}
          onClick={() => {
            logAnalyticsEvent(AnalyticsEvents.profile.avatarClicked);
            onOpen();
          }}
          showFallback
        />
      </div>
      <Input
        type="tel"
        label="شماره موبایل"
        readOnly
        value={`0${currentProfile.phone.substring(2)}`}
        classNames={{
          label: "origin-top-right",
        }}
      />
      <Input
        isRequired
        type="text"
        label="نام"
        disabled={submitState.isLoading}
        value={state.fname}
        maxLength={50}
        onChange={(event) =>
          setState({ ...state, fname: event.target.value.trim() })
        }
        classNames={{
          label: "origin-top-right",
        }}
        onClick={() => {
          logAnalyticsEvent(AnalyticsEvents.profile.firstNameInputClicked);
        }}
      />
      <Input
        type="text"
        label="نام خانوادگی"
        disabled={submitState.isLoading}
        value={state.lname ?? undefined}
        maxLength={50}
        onChange={(event) =>
          setState({ ...state, lname: event.target.value.trim() })
        }
        classNames={{
          label: "origin-top-right",
        }}
        onClick={() => {
          logAnalyticsEvent(AnalyticsEvents.profile.lastNameInputClicked);
        }}
      />
      <Input
        type="text"
        label="نام مستعار"
        disabled={submitState.isLoading}
        value={state.nickname ?? undefined}
        maxLength={50}
        onChange={(event) =>
          setState({ ...state, nickname: event.target.value.trim() })
        }
        classNames={{
          label: "origin-top-right",
        }}
        onClick={() => {
          logAnalyticsEvent(AnalyticsEvents.profile.nicknameInputClicked);
        }}
      />
      <div className="flex gap-2">
        <Input
          type="text"
          label="تاریخ تولد"
          readOnly
          value={new Intl.DateTimeFormat("fa-IR", {
            dateStyle: "medium",
          }).format(new Date(currentProfile.birthday!))}
          classNames={{
            label: "origin-top-right",
          }}
        />
        <Input
          type="text"
          label="جنسیت"
          readOnly
          value={currentProfile.gender === "Female" ? "دختر" : "پسر"}
          classNames={{
            label: "origin-top-right",
          }}
        />
      </div>
      <Button
        color="primary"
        isDisabled={
          state.fname.length === 0 ||
          (state.fname === currentProfile.fname &&
            state.lname === (currentProfile.lname ?? "") &&
            state.nickname === (currentProfile.nickname ?? "") &&
            state.avatar === (currentProfile.avatar ?? ""))
        }
        isLoading={submitState.isLoading}
        onPress={() => {
          logAnalyticsEvent(AnalyticsEvents.profile.submitButtonClicked);
          submitAction.invoke(state as UpdateProfileRequest);
        }}
      >
        ثبت
      </Button>
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        placement="auto"
        scrollBehavior="inside"
        backdrop="blur"
        hideCloseButton
      >
        <ModalContent>
          {(onClose) => {
            return (
              <ModalBody className="p-0 py-4" style={{ direction: "ltr" }}>
                <div className="flex flex-wrap justify-center gap-2">
                  {Array(36)
                    .fill(0)
                    .map((_, i) => `${i + 1}.jpg`)
                    .map((img) => (
                      <Avatar
                        className="w-24 h-24 cursor-pointer"
                        key={img}
                        src={"/avatars/" + img}
                        onClick={() => {
                          logAnalyticsEvent(
                            AnalyticsEvents.profile.avatarSelected,
                            {
                              avatar: img,
                            }
                          );
                          setState({ ...state, avatar: img });
                          onClose();
                        }}
                      />
                    ))}
                </div>
              </ModalBody>
            );
          }}
        </ModalContent>
      </Modal>
    </>
  );
}
