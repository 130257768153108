import { useCallback } from "react";

export function FeedbackEmojiItem(props: {
  emoji: string;
  title: string;
  rating: number;
  selectedRating: number | null;
  onSelected: (rating: number) => void;
}) {
  const { emoji, title, rating, selectedRating, onSelected } = props;

  const onSelectedCallback = useCallback(
    () => onSelected(rating),
    [onSelected, rating]
  );

  const isSelected = rating === selectedRating;
  const borderStyle = isSelected ? " border-primary" : " border-transparent";

  return (
    <div
      className={
        "transition-all flex flex-1 flex-col justify-center items-center cursor-pointer border-2 rounded-lg p-2" +
        borderStyle
      }
      onClick={onSelectedCallback}
    >
      <div className="text-4xl sm:text-5xl">{emoji}</div>
      <p className="font-medium">{title}</p>
    </div>
  );
}
