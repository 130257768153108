import { LocalDataSource } from "../../../core/core";
import Credentials from "./credentials";

export default class AuthLocaleDataSource extends LocalDataSource {
  private _credentialsKey = "credentials";

  setCredentials(credentials: Credentials): void {
    this.cacheManager.set(this._credentialsKey, credentials);
  }

  getCredentials(): Credentials | undefined {
    return this.cacheManager.get<Credentials>(this._credentialsKey);
  }

  removeCredentials(): void {
    this.cacheManager.remove(this._credentialsKey);
  }
}
