import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export function useInitNotificationsClickListener() {
  const navigate = useNavigate();

  const onMessage = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: MessageEvent<any>) => {
      const data = event.data;
      if (data?.action === "message_clicked") {
        if (data?.deeplink) {
          navigate(data.deeplink.replace(new URL(data.deeplink).origin, ""));
        }
      }
    },
    [navigate]
  );

  useEffect(() => {
    try {
      navigator.serviceWorker.addEventListener("message", onMessage);
    } catch (error) {
      console.error(error);
    }

    return () => {
      try {
        navigator.serviceWorker.removeEventListener("message", onMessage);
      } catch (error) {
        console.error(error);
      }
    };
  }, [onMessage]);
}
