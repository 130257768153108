import { Card, CardBody, CardFooter, Button } from "@heroui/react";
import { toPersianPrice } from "../../../../../core/core";
import {
  logAnalyticsEvent,
  AnalyticsEvents,
} from "../../../../firebase/firebase";
import { SubscriptionPlanCardItem } from "./SubscriptionPlanCardItem";
import { SubscriptionPlan } from "../../../domain/domain";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const SubscriptionPlanCard: React.FC<{
  plan: SubscriptionPlan;
  isViewOnly?: boolean;
  hasActiveSubscription?: boolean;
}> = ({ plan, isViewOnly = false, hasActiveSubscription = true }) => {
  const navigate = useNavigate();

  const onPlanClicked = useCallback(
    (plan: SubscriptionPlan) => {
      if (hasActiveSubscription) {
        toast.warning(
          "برای خرید اشتراک جدید، باید صبر کنی اشتراک فعلی تموم شه!"
        );
        return;
      }

      navigate(`/payment/checkout/${plan.id}`);
    },
    [hasActiveSubscription, navigate]
  );

  return (
    <Card
      isPressable={!isViewOnly}
      onPress={() => {
        logAnalyticsEvent(AnalyticsEvents.paymentPlans.planCardClicked, {
          id: plan.id,
          name: plan.name,
          duration: plan.duration,
          price: plan.price,
          trail: plan.trail,
          battery_multiplier: plan.features.batteryMultiplier.value,
          has_active_subscription: hasActiveSubscription.toString(),
        });
        onPlanClicked(plan);
      }}
    >
      <CardBody className="p-0 overflow-hidden">
        <div className="flex flex-row">
          <div className="flex flex-col flex-1 p-4 pb-0 items-stretch gap-2">
            <h4 className="font-medium text-3xl text-primary mb-2 text-right">
              {plan.name}
            </h4>
            <SubscriptionPlanCardItem>
              <span className="font-bold">
                {plan.features.batteryMultiplier.value * 5}
              </span>{" "}
              واحد باتری روزانه
            </SubscriptionPlanCardItem>
            <SubscriptionPlanCardItem>
              <span className="font-bold">
                {Math.floor((plan.features.batteryMultiplier.value * 5) / 2) *
                  plan.duration}
              </span>{" "}
              چت در طول اشتراک
            </SubscriptionPlanCardItem>
            <SubscriptionPlanCardItem>
              روزانه{" "}
              <span className="font-bold">
                {Math.floor((plan.features.batteryMultiplier.value * 5) / 2)}
              </span>{" "}
              چت با انتخاب جنسیت یا{" "}
              <span className="font-bold">
                {Math.floor(plan.features.batteryMultiplier.value * 5)}
              </span>{" "}
              چت شانسی
            </SubscriptionPlanCardItem>

            <div className="flex flex-row justify-end items-end gap-4">
              <h1 className="text-5xl text-center">
                {toPersianPrice(plan.price.toString())}
                <span className="text-small mr-2">تومان</span>
              </h1>
            </div>
          </div>
        </div>

        <div
          className={
            "absolute -left-12 top-[100px] -translate-y-full w-36 h-36 rounded-full " +
            (plan.features.batteryMultiplier.value === 2
              ? "bg-emerald-500"
              : plan.features.batteryMultiplier.value === 3
              ? "bg-indigo-500"
              : "bg-purple-600")
          }
        >
          <div className="w-60 h-36 flex justify-start items-end pb-3 pr-5">
            <span className="text-7xl font-thin text-white">
              {plan.features.batteryMultiplier.value}
              <span className="text-5xl">X</span>
            </span>
          </div>
        </div>
      </CardBody>

      {isViewOnly ? (
        <div className="h-2" />
      ) : (
        <CardFooter>
          <Button
            fullWidth
            color="primary"
            radius="full"
            size="sm"
            isDisabled={hasActiveSubscription}
            onPress={() => {
              logAnalyticsEvent(
                AnalyticsEvents.paymentPlans.planCardButtonClicked,
                {
                  id: plan.id,
                  name: plan.name,
                  duration: plan.duration,
                  price: plan.price,
                  trail: plan.trail,
                  battery_multiplier: plan.features.batteryMultiplier.value,
                  has_active_subscription: hasActiveSubscription.toString(),
                }
              );
              onPlanClicked(plan);
            }}
          >
            خرید
          </Button>
        </CardFooter>
      )}
    </Card>
  );
};
