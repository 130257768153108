import { DomainFailure, Result } from "../../../core/core";
import { OwnPromoCode } from "./entities/entities";
import { PromoCodeRemoteDataSource } from "./promo-code-remote-data-source";

export class PromoCodeRepository {
  constructor(private readonly _remoteDataSource: PromoCodeRemoteDataSource) {}

  async getOwnCode(): Promise<Result<DomainFailure, OwnPromoCode>> {
    const ownCodeResult = await this._remoteDataSource.getOwnCode();

    return ownCodeResult.fold({
      onFailure: async (failure) => {
        return Result.failure<DomainFailure, OwnPromoCode>(failure);
      },
      onSuccess: async (ownPromoCode) => {
        const usageCountResult =
          await this._remoteDataSource.getOwnCodeUsageCount();

        return usageCountResult.fold({
          onFailure: async (failure) => {
            return Result.failure<DomainFailure, OwnPromoCode>(failure);
          },
          onSuccess: async (usageCount) => {
            const hasPendingResult =
              await this._remoteDataSource.hasPendingRequest();

            return hasPendingResult.fold<Result<DomainFailure, OwnPromoCode>>({
              onFailure: (failure) => {
                return Result.failure<DomainFailure, OwnPromoCode>(failure);
              },
              onSuccess: (hasPending) => {
                return Result.success<DomainFailure, OwnPromoCode>({
                  ...ownPromoCode,
                  used: usageCount,
                  hasPending,
                });
              },
            });
          },
        });
      },
    });
  }

  searchCode(code: string): Promise<Result<DomainFailure, OwnPromoCode>> {
    return this._remoteDataSource.searchCode(code);
  }
}
