import {
  useDisclosure,
  Modal,
  ModalContent,
  Spinner,
  ModalHeader,
  ModalBody,
  Avatar,
  ModalFooter,
  Button,
} from "@heroui/react";
import { ReactNode, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AsyncValue, DomainFailure, FailureView } from "../../../../core/core";
import { chatRepository } from "../../../../core/services";
import { ChatRoom } from "../../../chat/domain/domain";
import { MatchFoundConfetti } from "./MatchFoundConfetti";
import { AnalyticsEvents, logAnalyticsEvent } from "../../../firebase/firebase";

export function useMatchFoundModal(): {
  matchFoundModal: ReactNode;
  openMatchFoundModal: (roomId?: string) => void;
} {
  const navigate = useNavigate();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();
  const [roomId, setRoomId] = useState<string>();
  const [room, setRoom] = useState(
    AsyncValue.initial<DomainFailure, ChatRoom>()
  );

  const loadRoom = useCallback(async (roomId: string) => {
    setRoom(AsyncValue.loadInProgress());

    const result = await chatRepository.getRoom(roomId);

    setRoom(
      result.fold({
        onSuccess: AsyncValue.loadSuccess,
        onFailure: AsyncValue.loadFailure,
      })
    );
  }, []);

  return {
    openMatchFoundModal: (roomId?: string) => {
      setRoomId(roomId);
      loadRoom(roomId!);
      onOpen();
    },
    matchFoundModal: (
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        hideCloseButton={true}
        size="md"
      >
        {room.maybeFold({
          orElse: () => <></>,
          loadSuccess: () => <MatchFoundConfetti />,
        })}
        <ModalContent>
          {(onClose) =>
            room.maybeFold({
              orElse: () => (
                <div className="min-h-[312px] flex items-center justify-center">
                  <Spinner />
                </div>
              ),
              loadFailure: (failure) => (
                <FailureView
                  failure={failure}
                  onRetry={() => loadRoom(roomId!)}
                />
              ),
              loadSuccess: (room) => (
                <>
                  <ModalHeader className="flex flex-col gap-1 p-4 items-center">
                    با موفقیت وصل شدی!
                  </ModalHeader>
                  <ModalBody className="px-4 flex flex-col justify-center items-center">
                    <Avatar
                      src={"/avatars/" + room.participant.avatar}
                      showFallback
                      className="w-32 h-32"
                    />
                    <p>{room.participant.name}</p>
                  </ModalBody>
                  <ModalFooter className="p-4">
                    <Button
                      color="primary"
                      fullWidth
                      onPress={() => {
                        logAnalyticsEvent(
                          AnalyticsEvents.matchMaking
                            .matchFoundDialogStartChatButtonClicked,
                          {
                            room_id: roomId ?? "",
                          }
                        );
                        onClose();
                        navigate(`/${roomId!}`);
                      }}
                    >
                      شروع چت
                    </Button>
                  </ModalFooter>
                </>
              ),
            })
          }
        </ModalContent>
      </Modal>
    ),
  };
}
