import { Modal, ModalContent, ModalBody } from "@heroui/react";
import { lazy, Suspense, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MatchMakingProps } from "./MatchMaking";
import { SuspenseLoading } from "../../../core/core";

// eslint-disable-next-line react-refresh/only-export-components
const MatchMaking = lazy(() => import("../../match_making/view/MatchMaking"));

export function useMatchMakingModal(props: MatchMakingProps): {
  matchMakingModal: React.ReactNode;
  openMatchMakingModal: () => void;
} {
  const navigate = useNavigate();
  const location = useLocation();
  const isProfileOpen = location.hash === "#match-making";
  const onOpen = useCallback(() => {
    navigate("/#match-making");
  }, [navigate]);
  const onOpenChange = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        onOpen();
      } else {
        history.back();
      }
    },
    [onOpen]
  );

  const {
    matchMakingState,
    startMatchMakingState,
    startMatchmaking,
    openCancelConfirmModal,
    closeCancelConfirmModal,
  } = props;

  return {
    openMatchMakingModal: onOpen,
    matchMakingModal: (
      <Modal
        isOpen={isProfileOpen}
        onOpenChange={onOpenChange}
        hideCloseButton={true}
        size="4xl"
        backdrop="blur"
        scrollBehavior="inside"
      >
        <ModalContent>
          <ModalBody className="p-4">
            <Suspense fallback={<SuspenseLoading />}>
              <MatchMaking
                matchMakingState={matchMakingState}
                startMatchMakingState={startMatchMakingState}
                startMatchmaking={startMatchmaking}
                openCancelConfirmModal={openCancelConfirmModal}
                closeCancelConfirmModal={closeCancelConfirmModal}
              />
            </Suspense>
          </ModalBody>
        </ModalContent>
      </Modal>
    ),
  };
}
