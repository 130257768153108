import { useCallback, useEffect, useRef, useState } from "react";
import {
  AsyncAction,
  AsyncValue,
  DomainFailure,
  matchmakingRepository,
  Nothing,
  showErrorToast,
  useAsyncAction,
  useStore,
} from "../../../core/core";
import {
  MatchMakingState,
  StartMatchmakingRequest,
  StartMatchmakingRequestGender,
} from "../match-making";
import { toast } from "react-toastify";
import { AnalyticsEvents, logAnalyticsEvent } from "../../firebase/firebase";

export function useMatchMaking(props: {
  onMatchFound: (roomId: string) => void;
  onTimeoutEnded: () => void;
  onInsufficientSpark: () => void;
  onCoolDownNotFinished: (endDuration: number) => void;
}): {
  matchMakingState: AsyncValue<DomainFailure, MatchMakingState>;
  startMatchMakingState: AsyncAction<DomainFailure, Nothing>;
  startMatchmaking: (preferredGender: StartMatchmakingRequestGender) => void;
  onCancelSuccess: () => void;
} {
  const {
    onMatchFound,
    onInsufficientSpark,
    onTimeoutEnded,
    onCoolDownNotFinished,
  } = props;

  const [matchMakingState, setMatchMakingState] = useState<
    AsyncValue<DomainFailure, MatchMakingState>
  >(AsyncValue.initial());

  const [startMatchMakingState, submitStartMatchMakingAction] = useAsyncAction({
    action: (params: StartMatchmakingRequest) =>
      matchmakingRepository.startMatchmaking(params),
    onFailure: showErrorToast,
  });

  const onTimeoutReached = useCallback(() => {
    setMatchMakingState(
      AsyncValue.loadSuccess({ state: "Free", roomId: null, timeout: null })
    );
    onTimeoutEnded();
  }, [onTimeoutEnded]);

  const timeoutTimer = useRef<ReturnType<typeof setTimeout>>();
  const setTimeoutTimer = useCallback(
    (timeout: Date | null) => {
      clearTimeout(timeoutTimer.current);

      if (timeout) {
        timeoutTimer.current = setTimeout(() => {
          onTimeoutReached();
        }, timeout.getTime() - new Date().getTime());
      }
    },
    [onTimeoutReached]
  );

  useEffect(() => {
    setTimeoutTimer(
      matchMakingState.maybeFold({
        orElse: () => null,
        loadSuccess: (state) => state.timeout,
      })
    );
  }, [matchMakingState, setTimeoutTimer]);

  useEffect(() => {
    const subscription = matchmakingRepository
      .watchMatchmakingState()
      .subscribe((matchMakingState: MatchMakingState) => {
        if (matchMakingState.state === "Matched") {
          matchmakingRepository.updateLastSuccessMatchDate(new Date());
          onMatchFound(matchMakingState.roomId!);
          setMatchMakingState(
            AsyncValue.loadSuccess({
              state: "Free",
              roomId: null,
              timeout: null,
            })
          );
          useStore.getState().getBatteryState();
        } else if (matchMakingState.state === "Failed") {
          toast.error("مشکلی پیش اومد. لطفاً دوباره تلاش کن.");
          setMatchMakingState(
            AsyncValue.loadSuccess({
              state: "Free",
              roomId: null,
              timeout: null,
            })
          );
        } else {
          setMatchMakingState(AsyncValue.loadSuccess(matchMakingState));
        }
      });

    return () => subscription.unsubscribe();
  }, [onMatchFound]);

  useEffect(() => {
    loadMatchMakingState();
  }, []);

  const loadMatchMakingState = async () => {
    setMatchMakingState(AsyncValue.loadInProgress());

    const result = await matchmakingRepository.getState();

    setMatchMakingState(
      result.fold({
        onFailure: AsyncValue.loadFailure,
        onSuccess: AsyncValue.loadSuccess,
      })
    );
  };

  const startMatchmaking = useCallback(
    (preferredGender: StartMatchmakingRequestGender) => {
      const isCoolDownOver = matchmakingRepository.isCoolDownOver();
      const coolDownEndInSeconds =
        matchmakingRepository.getCoolDownEndInSeconds() ?? 0;
      const batteryCharge = useStore.getState().batteryState.maybeFold({
        orElse: () => 0,
        loadSuccess: (state) => state.charge,
      });
      const hasEnoughCharge =
        preferredGender === "Any" ? batteryCharge >= 1 : batteryCharge >= 2;

      logAnalyticsEvent(AnalyticsEvents.matchMaking.started, {
        gender: preferredGender,
        battery_charge: batteryCharge,
        has_enough_charge: hasEnoughCharge.toString(),
        is_cool_down_over: isCoolDownOver.toString(),
        cool_down_end_in_seconds: coolDownEndInSeconds,
        last_success_match_date:
          matchmakingRepository.getLastSuccessMatchDate()?.toISOString() ?? "",
      });

      if (!hasEnoughCharge) {
        onInsufficientSpark();
        return;
      }

      if (!isCoolDownOver) {
        onCoolDownNotFinished(coolDownEndInSeconds);
        return;
      }

      submitStartMatchMakingAction.invoke({ preferredGender });
    },
    [onCoolDownNotFinished, onInsufficientSpark, submitStartMatchMakingAction]
  );

  const onCancelSuccess = useCallback(() => {
    setMatchMakingState(
      AsyncValue.loadSuccess({ state: "Free", roomId: null, timeout: null })
    );
  }, []);

  return {
    matchMakingState,
    startMatchMakingState,
    startMatchmaking,
    onCancelSuccess,
  };
}
