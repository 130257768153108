import { useEffect, useMemo, useState } from "react";
import { Result } from "../../domain/domain";
import { AsyncValue, AsyncValueController } from "./async-value";

function useAsyncValueState<F, S>(
  controller: AsyncValueController<F, S>
): AsyncValue<F, S> {
  const [state, setState] = useState<AsyncValue<F, S>>(controller.state.value);

  useEffect(() => {
    const subscription = controller.state.subscribe({
      next: (newState) => {
        if (state === newState) {
          return;
        }

        setState(newState);
      },
    });

    return () => subscription.unsubscribe();
  }, [controller, state]);

  return state;
}

export function useAsyncValue<F, S>(props: {
  getData: (params: unknown) => Promise<Result<F, S>>;
}): [AsyncValue<F, S>, AsyncValueController<F, S>] {
  const asyncValueController = useMemo(
    () => new AsyncValueController(props.getData),
    [props.getData]
  );

  const state = useAsyncValueState<F, S>(asyncValueController);

  return [state, asyncValueController];
}
