import { DomainFailure, Nothing, Result } from "../../../core/core";
import {
  FeedbackStatus,
  SkipFeedbackRequest,
  SubmitFeedbackRequest,
} from "./entities/entities";
import { FeedbackRemoteDataSource } from "./feedback-remote-data-source";

export class FeedbackRepository {
  constructor(private readonly _remoteDataSource: FeedbackRemoteDataSource) {}

  async get(label: string): Promise<Result<DomainFailure, FeedbackStatus>> {
    const feedbackResult = await this._remoteDataSource.get(label);

    return feedbackResult.fold({
      onFailure: async (failure) => {
        return Result.failure<DomainFailure, FeedbackStatus>(failure);
      },
      onSuccess: async (feedback) => {
        // If a feedback with such label did not exist
        if (!feedback.id) {
          return Result.success<DomainFailure, FeedbackStatus>({
            feedback,
            canSubmitAnswer: false,
          });
        }

        const canSubmitAnswerResult =
          await this._remoteDataSource.canSubmitAnswer(feedback.id);

        return canSubmitAnswerResult.fold<
          Result<DomainFailure, FeedbackStatus>
        >({
          onFailure: (failure) => {
            return Result.failure<DomainFailure, FeedbackStatus>(failure);
          },
          onSuccess: (canSubmitAnswer) => {
            return Result.success<DomainFailure, FeedbackStatus>({
              feedback,
              canSubmitAnswer,
            });
          },
        });
      },
    });
  }

  submit(
    request: SubmitFeedbackRequest
  ): Promise<Result<DomainFailure, Nothing>> {
    return this._remoteDataSource.submit(request);
  }

  skip(request: SkipFeedbackRequest): Promise<Result<DomainFailure, Nothing>> {
    return this._remoteDataSource.skip(request);
  }
}
