import { DomainFailure, RemoteDataSource, Result } from "../../../core/core";
import {
  ChatRoom,
  ChatMessage,
  ChatRoomSummary,
  GetMessagesRequest,
  UserOnlineStatus,
} from "./entities/entities";

export class ChatRemoteDataSource extends RemoteDataSource {
  async getRooms(): Promise<Result<DomainFailure, ChatRoomSummary[]>> {
    const result = await this.httpClient.get("/room");

    return result.map((data) => data as ChatRoomSummary[]);
  }

  async getRoom(roomId: string): Promise<Result<DomainFailure, ChatRoom>> {
    const result = await this.httpClient.get(`/room/${roomId}`);

    return result.map((data) => data as ChatRoom);
  }

  async getUnreadMessages(
    roomId: string
  ): Promise<Result<DomainFailure, ChatMessage[]>> {
    const result = await this.httpClient.get(`/message/${roomId}/unread`);

    return result.map((data) => data as ChatMessage[]);
  }

  async getMessages(
    params: GetMessagesRequest
  ): Promise<Result<DomainFailure, ChatMessage[]>> {
    const { roomId, cursor, direction, limit } = params;

    const result = await this.httpClient.get(`/message/${roomId}/history`, {
      queryParameters: {
        cursor,
        direction,
        limit,
      },
    });

    return result.map((data) => data as ChatMessage[]);
  }

  async getUserOnlineStatus(
    userId: string
  ): Promise<Result<DomainFailure, UserOnlineStatus>> {
    const result = await this.httpClient.get(
      `/profile/online-status/${userId}`
    );

    return result.map((data) => data as UserOnlineStatus);
  }
}
