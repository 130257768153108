import {
  Button,
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  useDisclosure,
} from "@heroui/react";
import { ReactNode } from "react";
import { FontAwesomeSvgIcon } from "../../../../core/core";
import { useNavigate } from "react-router-dom";
import { AnalyticsEvents, logAnalyticsEvent } from "../../../firebase/firebase";
import { faBagShopping } from "@fortawesome/free-solid-svg-icons";

export function useNoSparkModal(): {
  noSparkModal: ReactNode;
  openNoSparkModal: () => void;
} {
  const navigate = useNavigate();
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return {
    openNoSparkModal: onOpen,
    noSparkModal: (
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        hideCloseButton={true}
        size="xl"
        backdrop="blur"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 p-4">
                باتریت شارژ نداره!
              </ModalHeader>
              <ModalBody className="px-4">
                <p>
                  🔋 همین حالا باتریت رو{" "}
                  <Link
                    onClick={() => {
                      onClose();
                      logAnalyticsEvent(
                        AnalyticsEvents.matchMaking
                          .noSparkDialogChargeBatteryLinkClicked
                      );
                      navigate("/#battery");
                    }}
                  >
                    شارژ کن
                  </Link>
                  !
                </p>
                <p>
                  🚀 اگه هنوز زمان شارژت نرسیده، با{" "}
                  <span className="font-bold">خرید اشتراک</span> ظرفیت باتریت رو
                  افزایش بده تا چت‌های بیشتری داشته باشی!
                </p>
              </ModalBody>
              <ModalFooter className="p-4">
                <Button
                  variant="bordered"
                  color="primary"
                  fullWidth
                  onPress={() => {
                    logAnalyticsEvent(
                      AnalyticsEvents.matchMaking
                        .noSparkDialogMyBatteryButtonClicked
                    );
                    onClose();
                    navigate("/#battery");
                  }}
                >
                  باتری من
                </Button>
                <Button
                  variant="solid"
                  color="primary"
                  fullWidth
                  startContent={<FontAwesomeSvgIcon icon={faBagShopping} />}
                  onPress={() => {
                    logAnalyticsEvent(
                      AnalyticsEvents.matchMaking
                        .noSparkDialogBuySparkButtonClicked
                    );
                    onClose();
                    navigate("/#plans");
                  }}
                >
                  خرید اشتراک
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    ),
  };
}
