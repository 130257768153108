import { ReactNode, useState, useEffect } from "react";
import { toPersianDigits } from "../../core";

export function Timer(props: {
  end: Date;
  renderTime?: (remainingTime: string) => string;
  endComponent?: ReactNode;
  onEnd?: () => void;
}) {
  const [remainingDuration, setRemainingDuration] = useState<number>(0);

  useEffect(() => {
    const remainingDuration = props.end.getTime() - new Date().getTime();

    setRemainingDuration(remainingDuration / 1000);

    const timer = setInterval(() => {
      const remainingDuration = props.end.getTime() - new Date().getTime();

      setRemainingDuration(remainingDuration / 1000);

      if (remainingDuration < 0) {
        if (props.onEnd) props.onEnd();
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [props]);

  if (remainingDuration < 0) {
    return props.endComponent ?? <p>{toPersianDigits("00:00")}</p>;
  }

  const days = Math.floor(remainingDuration / 60 / 60 / 24);
  const hours = Math.floor((remainingDuration / 60 / 60) % 24);
  const minutes = Math.floor((remainingDuration / 60) % 60);
  const seconds = Math.floor(remainingDuration % 60);

  let remainingTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  if (hours !== 0 || days !== 0) {
    remainingTime = `${hours.toString().padStart(2, "0")}:${remainingTime}`;
  }

  if (days !== 0) {
    remainingTime = `${days}:${remainingTime}`;
  }

  remainingTime = toPersianDigits(remainingTime);

  return (
    <p>{props?.renderTime ? props.renderTime(remainingTime) : remainingTime}</p>
  );
}
