import { DomainFailure, Result } from "../../../core/core";
import { ReferralCodeStatistic } from "./entities/ReferralCodeStatistic";
import { ReferralRemoteDataSource } from "./referral-remote-data-source";

export class ReferralRepository {
  constructor(private readonly _remoteDataSource: ReferralRemoteDataSource) {}

  getReferralDetail(): Promise<Result<DomainFailure, string>> {
    return this._remoteDataSource.getMyCode();
  }

  getMyCode(): Promise<Result<DomainFailure, string>> {
    return this._remoteDataSource.getMyCode();
  }

  getMyCodeStatistic(): Promise<Result<DomainFailure, ReferralCodeStatistic>> {
    return this._remoteDataSource.getMyCodeStatistic();
  }
}
