import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
  Textarea,
} from "@heroui/react";
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  feedbackRepository,
  showErrorToast,
  useAsyncAction,
  useAsyncValue,
} from "../../../core/core";
import { FeedbackEmojiItem } from "./components/FeedbackEmojiItem";
import { SubmitFeedbackRequest } from "../domain/entities/entities";

export function useInitFeedback(): {
  feedbackModal: ReactNode;
} {
  const { isOpen, onOpenChange, onOpen, onClose } = useDisclosure();
  const [selectedRating, setSelectedRating] = useState<number | null>(null);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const [feedbackState, asyncValueController] = useAsyncValue({
    getData: useCallback(() => feedbackRepository.get("weekly_feedback"), []),
  });

  useEffect(() => {
    asyncValueController.invoke({});
  }, [asyncValueController]);

  useEffect(() => {
    feedbackState.maybeFold({
      loadSuccess: (feedbackStatus) => {
        if (feedbackStatus.canSubmitAnswer) {
          onOpen();
        }
      },
      orElse: () => {},
    });
  }, [feedbackState, onOpen]);

  const feedbackStatus = useMemo(
    () =>
      feedbackState.maybeFold({
        orElse: () => null,
        loadSuccess: (feedbackStatus) => feedbackStatus,
      }),
    [feedbackState]
  );

  const [submitState, submitAction] = useAsyncAction({
    action: (params: SubmitFeedbackRequest & { skip: boolean }) =>
      params.skip
        ? feedbackRepository.skip({ feedbackId: params.feedbackId })
        : feedbackRepository.submit(params),
    onFailure: showErrorToast,
    onSuccess: onClose,
  });

  return {
    feedbackModal: (
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        isDismissable={!submitState.isLoading}
        isKeyboardDismissDisabled={submitState.isLoading}
        hideCloseButton={true}
        size="md"
      >
        <ModalContent>
          <ModalHeader className="flex flex-col gap-1 p-4">
            {feedbackStatus?.feedback.title ??
              "تجربه‌ات در فندق تو این مدت چطور بوده؟"}
          </ModalHeader>
          <ModalBody className="px-4">
            <div className="flex flex-row-reverse gap-2 mb-4">
              <FeedbackEmojiItem
                emoji="😡"
                title="افتضاح"
                rating={1}
                selectedRating={selectedRating}
                onSelected={setSelectedRating}
              />
              <FeedbackEmojiItem
                emoji="🙁"
                title="بد"
                rating={2}
                selectedRating={selectedRating}
                onSelected={setSelectedRating}
              />
              <FeedbackEmojiItem
                emoji="😐"
                title="معمولی"
                rating={3}
                selectedRating={selectedRating}
                onSelected={setSelectedRating}
              />
              <FeedbackEmojiItem
                emoji="🙂"
                title="خوب"
                rating={4}
                selectedRating={selectedRating}
                onSelected={setSelectedRating}
              />
              <FeedbackEmojiItem
                emoji="🤩"
                title="عالی"
                rating={5}
                selectedRating={selectedRating}
                onSelected={setSelectedRating}
              />
            </div>

            <h1>اگر نظر، پیشنهاد یا انتقادی داری، لطفاً اینجا برامون بنویس:</h1>

            <Textarea
              isDisabled={submitState.isLoading}
              ref={inputRef}
              minRows={3}
              maxRows={5}
              placeholder=""
            />

            <h1>مرسی که کمک می‌کنی فندق بهتر بشه! ❤️</h1>
          </ModalBody>
          <ModalFooter className="p-4">
            <Button
              color="primary"
              variant="bordered"
              isDisabled={submitState.isLoading}
              onPress={() => {
                onClose();
                submitAction.invoke({
                  feedbackId: feedbackStatus?.feedback.id,
                  skip: true,
                });
              }}
              fullWidth
            >
              بی‌خیال
            </Button>
            <Button
              color="primary"
              variant="solid"
              isLoading={submitState.isLoading}
              onPress={() =>
                submitAction.invoke({
                  feedbackId: feedbackStatus?.feedback.id,
                  answer: selectedRating?.toString().trim(),
                  comment: inputRef.current?.value,
                })
              }
              fullWidth
            >
              ثبت نظر
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    ),
  };
}
