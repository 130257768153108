import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@heroui/react";
import {
  EnvironmentVariables,
  FontAwesomeSvgIcon,
  SocketConnectionState,
} from "../../../../core/core";
import { useSocketConnectionState } from "../../utils/useSocketConnectionState";
import { BatteryBadge } from "../../../battery/view/components/BatteryBadge";
import { AnalyticsEvents, logAnalyticsEvent } from "../../../firebase/firebase";
import { useEffect, useRef } from "react";

export function AppBar(props: {
  onMenuClicked: () => void;
  onBatteryClicked: () => void;
  isRoomOpenOnMobile: boolean;
}) {
  const socketConnectionState = useSocketConnectionState();
  const isConnected =
    EnvironmentVariables.isPreLaunch ||
    socketConnectionState === SocketConnectionState.connected;

  const canOpenBatteryPage = useRef(!props.isRoomOpenOnMobile);
  useEffect(() => {
    setTimeout(() => {
      canOpenBatteryPage.current = !props.isRoomOpenOnMobile;
    }, 10);
  }, [props.isRoomOpenOnMobile]);

  return (
    <div
      className="bg-white flex flex-row items-center px-3 border-b-1"
      style={{ minHeight: "61px" }}
    >
      <Button
        isIconOnly
        variant="light"
        className="ml-1"
        onPress={props.onMenuClicked}
        radius="full"
      >
        <FontAwesomeSvgIcon icon={faBars} />
      </Button>
      <div className="flex flex-col flex-grow justify-center items-start space-x-2 relative">
        <div
          className="flex flex-row items-center absolute transition-all duration-100"
          style={{
            top: !isConnected ? "-12px" : "-50px",
            opacity: !isConnected ? 1 : 0,
          }}
        >
          <h1 className="text-base font-medium ml-1">در حال اتصال</h1>
          <div className="loader"></div>
        </div>
        <h1
          className="text-base font-medium absolute transition-all duration-100"
          style={{
            top: isConnected ? "-12px" : "-50px",
            opacity: isConnected ? 1 : 0,
          }}
        >
          فندق
        </h1>
      </div>

      <BatteryBadge
        onClicked={() => {
          if (canOpenBatteryPage.current) {
            props.onBatteryClicked();
            logAnalyticsEvent(AnalyticsEvents.home.batteryClicked);
          }
        }}
      />
    </div>
  );
}
