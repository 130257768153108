import { faBoxOpen, faBagShopping } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@heroui/react";
import { FontAwesomeSvgIcon } from "../../../../../core/core";
import { useNavigate } from "react-router-dom";
import {
  AnalyticsEvents,
  logAnalyticsEvent,
} from "../../../../firebase/firebase";

export function PaymentHistoryEmptyState() {
  const navigate = useNavigate();

  return (
    <div className="flex flex-col items-stretch gap-2 mt-2">
      <FontAwesomeSvgIcon icon={faBoxOpen} size="3x" />
      <h1 className="text-lg font-medium text-center">
        هنوز خریدی انجام ندادی!
      </h1>
      <h2 className="text-base text-center">
        اشتراک بخر، گفتگوهای تازه و جذاب رو همین لحظه تجربه کن!
      </h2>
      <Button
        className="mt-2"
        variant="solid"
        color="primary"
        onPress={() => {
          logAnalyticsEvent(
            AnalyticsEvents.paymentHistory.buySparkButtonClicked
          );
          navigate("/#plans");
        }}
        startContent={<FontAwesomeSvgIcon icon={faBagShopping} />}
      >
        خرید اشتراک
      </Button>
    </div>
  );
}
