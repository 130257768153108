import { map, Observable } from "rxjs";
import {
  DomainFailure,
  nothing,
  Nothing,
  Result,
  SocketDataSource,
} from "../../../core/core";
import { MatchMakingState, StartMatchmakingRequest } from "./entities/entities";

export class MatchmakingSocketDataSource extends SocketDataSource {
  async startMatchmaking(
    params: StartMatchmakingRequest
  ): Promise<Result<DomainFailure, Nothing>> {
    const result = await this.socketClient.send({
      action: "start_matchmaking",
      payload: params,
    });

    return result.map((_) => nothing);
  }

  async cancelMatchmaking(): Promise<Result<DomainFailure, Nothing>> {
    const result = await this.socketClient.send({
      action: "cancel_matchmaking",
      payload: {},
    });

    return result.map((_) => nothing);
  }

  watchMatchmakingState(): Observable<MatchMakingState> {
    return this.socketClient
      .listen("matchmaking_state")
      .pipe(map((data) => data as MatchMakingState));
  }
}
