import { DomainFailure, Nothing, Result } from "../../../core/core";
import {
  CompleteProfileRequest,
  Profile,
  UpdateProfileRequest,
} from "./models/models";
import ProfileRemoteDataSource from "./profile-remote-data-source";

export default class ProfileRepository {
  constructor(
    private readonly profileRemoteDataSource: ProfileRemoteDataSource
  ) {}

  public get(): Promise<Result<DomainFailure, Profile>> {
    return this.profileRemoteDataSource.get();
  }

  public complete(
    request: CompleteProfileRequest
  ): Promise<Result<DomainFailure, Nothing>> {
    return this.profileRemoteDataSource.complete(request);
  }

  public update(
    request: UpdateProfileRequest
  ): Promise<Result<DomainFailure, Profile>> {
    return this.profileRemoteDataSource.update(request);
  }
}
