import { Card } from "@heroui/react";
import Logo from "/logo.png";
import { lazy, Suspense } from "react";
import { SuspenseLoading } from "../../../../core/core";

const Referral = lazy(() =>
  import("../../../referral/view/Referral").then((module) => ({
    default: module.Referral,
  }))
);

export function PreLaunchDescription() {
  return (
    <div className="w-full overflow-scroll">
      <div className="flex flex-col items-center justify-center p-4 min-h-[calc(100vh-61px)] sm:h-[98vh]">
        <div className="flex-grow sm:flex-grow-0 flex flex-col justify-center">
          <div className="flex flex-row justify-center items-center gap-4 mb-4">
            <img src={Logo} alt="Fandogh Logo" width={56} />
            <h1 className="text-4xl">فندق داره میاد! 🎉</h1>
          </div>
          <h2 className="text-base text-center leading-8">
            داریم برای یه شروع پرانرژی آماده می‌شیم!
            <br />
            دوستاتو دعوت کن، اسپارک ⚡️ جمع کن و منتظر باش که اولین چت ناشناستو
            شروع کنی.
          </h2>
        </div>

        <Card className="max-w-md p-3 mt-4 sm:mt-8">
          <Suspense fallback={<SuspenseLoading />}>
            <Referral />
          </Suspense>
        </Card>
      </div>
    </div>
  );
}
