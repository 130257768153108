import { Spinner } from "@heroui/react";

export function SuspenseLoading(props: { useVw?: boolean }) {
  return (
    <div
      className={
        "flex items-center justify-center " +
        (props.useVw ? "h-screen" : "h-full")
      }
    >
      <Spinner />
    </div>
  );
}
