import { DomainFailure } from "../../domain/domain";
import { toast } from "react-toastify";
import WifiOff from "../../../assets/icons/wifi_off.svg";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeSvgIcon } from "./FontAwesomeSvgIcon";

export function showErrorToast(failure: DomainFailure) {
  failure.fold({
    onNetworkFailure: () =>
      toast.error("ارتباط اینترنت خود را بررسی و مجدد امتحان کنید.", {
        icon: <img src={WifiOff} alt="Wifi Off Icon" />,
      }),
    onServerFailure: (_) =>
      toast.error("مشکلی رخ داده است. لطفا مجدد تلاش کنید.", {
        icon: <FontAwesomeSvgIcon icon={faTriangleExclamation} />,
      }),
  });
}
