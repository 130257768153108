import { DomainFailure, nothing, Nothing, Result } from "../../../core/core";
import AuthLocaleDataSource from "./auth-local-data-source";
import AuthRemoteDataSource from "./auth-remote-data-source";

export class AuthRepository {
  constructor(
    private readonly _remoteDataSource: AuthRemoteDataSource,
    private readonly _localDataSource: AuthLocaleDataSource
  ) {}

  getAccessToken(): string | undefined {
    return this._localDataSource.getCredentials()?.accessToken;
  }

  removeCredentials(): void {
    this._localDataSource.removeCredentials();
  }

  register(phone: string): Promise<Result<DomainFailure, string>> {
    return this._remoteDataSource.register(phone);
  }

  async verify(
    token: string,
    code: string,
    inviteCode: string | null
  ): Promise<Result<DomainFailure, Nothing>> {
    const result = await this._remoteDataSource.verify(token, code, inviteCode);

    return result.map((data) => {
      this._localDataSource.setCredentials(data);

      return nothing;
    });
  }

  async renewToken(): Promise<Result<DomainFailure, Nothing>> {
    const result = await this._remoteDataSource.renewToken(
      this._localDataSource.getCredentials()!.accessToken
    );

    return result.map((data) => {
      this._localDataSource.setCredentials(data);

      return nothing;
    });
  }

  async logOut(): Promise<Result<DomainFailure, Nothing>> {
    const result = await this._remoteDataSource.logOut();

    if (result.isSuccess) {
      localStorage.clear();
    }

    return result;
  }
}
