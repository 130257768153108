import { useCallback, useEffect, useState } from "react";
import { PaymentFailure } from "./components/PaymentFailure";
import { PaymentSuccess } from "./components/PaymentSuccess";
import {
  AsyncValue,
  DomainFailure,
  FailureView,
  paymentRepository,
} from "../../../../core/core";
import { PaymentResultSummary } from "../../domain/domain";
import { useParams } from "react-router-dom";
import { Spinner } from "@heroui/react";

export function PaymentResult() {
  const { paymentId } = useParams();

  const [state, setState] = useState<
    AsyncValue<DomainFailure, PaymentResultSummary>
  >(AsyncValue.initial());

  const loadData = useCallback(async () => {
    setState(AsyncValue.loadInProgress());

    const result = await paymentRepository.getPurchasedPlanSummary(paymentId!);

    setState(
      result.fold({
        onSuccess: AsyncValue.loadSuccess,
        onFailure: AsyncValue.loadFailure,
      })
    );
  }, [paymentId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className="flex flex-col md:items-center justify-center sm:h-screen">
      {state.maybeFold({
        orElse: () => <Spinner />,
        loadFailure: (failure) => (
          <FailureView failure={failure} onRetry={loadData} />
        ),
        loadSuccess: (summary) => (
          <div className="w-auto md:w-96">
            {summary.status === "Success" ? (
              <PaymentSuccess
                price={summary.price / 10}
                orderId={summary.orderId}
              />
            ) : (
              <PaymentFailure orderId={summary.orderId} />
            )}
          </div>
        ),
      })}
    </div>
  );
}
