import { ReactNode } from "react";
import { FontAwesomeSvgIcon } from "../../../../../core/core";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";

export const SubscriptionPlanCardItem = (props: { children: ReactNode }) => {
  return (
    <div className="flex flex-row items-start">
      <FontAwesomeSvgIcon
        icon={faCircleCheck}
        size="lg"
        className="text-green-500 ml-2"
      />
      <div className="text-sm text-right">{props.children}</div>
    </div>
  );
};
