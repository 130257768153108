import { faShieldHalved } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeSvgIcon } from "../../../core/core";
import Logo from "/logo.png";

export function PrivacyPage() {
  return (
    <div className="flex flex-col h-screen">
      <div className="flex items-center justify-center shadow-md">
        <img src={Logo} alt="Fandogh Logo" width={42} />
        <h1 className="p-4 text-3xl" style={{ textAlign: "center" }}>
          فندق
        </h1>
      </div>

      <div className="flex-grow p-6 pt-0 max-w-screen-md text-justify m-auto flex flex-col items-stretch overflow-scroll">
        <div className="flex flex-row items-center py-4">
          <FontAwesomeSvgIcon icon={faShieldHalved} size="2xl" />
          <h1 className="pr-4 text-2xl">حریم خصوصی کاربران</h1>
        </div>

        <p className="py-2">
          فندق با تأکید بر احترامی که برای حریم خصوصی کاربران خود قائل است،
          اطلاعاتی را جهت ارائه خدمات و تجربه‌ای امن و با کیفیت از کاربران
          دریافت می‌کند. این اطلاعات تنها برای اهداف مشخص و با رعایت کامل قوانین
          حفاظت از داده‌ها استفاده می‌شود. برای استفاده از خدمات اطلاعات زیر
          ممکن است از شما جمع‌آوری شود:
        </p>
        <ul className="list-disc list-inside py-2">
          <li>شماره تلفن همراه جهت ایجاد حساب کاربری</li>
          <li>جنسیت جهت اتصال شما به افراد دیگر</li>
          <li>تاریخ تولد جهت جلوگیری از ثبت نام افراد زیر ۱۸ سال</li>
          <li>جزئیات تراکنش‌های مربوط به خرید پلن‌ها</li>
          <li>نشست های کاربر</li>
        </ul>
        <p className="py-2">
          در صورت ورود تاریخ تولدی که مطابق با سن واقعی کاربر نباشد، مسئولیت آن
          بر عهده کاربر است و سیستم ما هیچ مسئولیتی در قبال اطلاعات نادرست وارد
          شده ندارد. تمامی کاربران باید مطمئن شوند که اطلاعات خود را به درستی
          وارد می‌کنند.
        </p>
        <p className="py-2">
          برای جلوگیری از هرگونه سوء استفاده احتمالی و حفظ امنیت سیستم، پس از
          ثبت‌نام، کاربران قادر به تغییر جنسیت خود نخواهند بود. این اقدام
          به‌منظور جلوگیری از فریب و سوءاستفاده، مانند جعل هویت یا جایگزینی خود
          به‌جای فرد دیگری، انجام شده است.{" "}
        </p>
        <p className="py-2">
          سیستم ما مسئولیت اطلاعاتی که در هنگام استفاده از خدمات به اشتراک
          گذاشته می‌شود را ندارد. مسئولیت محتوای رد و بدل شده بر عهده شخصی است
          که آن محتوا را منتشر کرده است. ما تنها به ارائه یک پلتفرم برای اتصال
          کاربران و تأمین امنیت خدمات تمرکز داریم و هیچ‌گونه دخالتی در محتوای
          تبادل شده نداریم.
        </p>
        <p className="py-2">
          اطلاعات کاربران با اشخاص ثالث به اشتراک گذاشته نمی‌شود، مگر در مواردی
          که الزامات قانونی توسط مراجع قضایی وجود داشته باشد یا برای تضمین امنیت
          کاربران و خدمات اپلیکیشن ضروری باشد.
        </p>
        <p className="py-2">
          فندق تمامی تلاش خود را برای فراهم کردن محیطی امن و محرمانه برای
          کاربران به کار می‌گیرد و اطلاعات شخصی کاربران را تنها در محدوده‌ای که
          برای ارائه خدمات مورد نیاز است استفاده می‌کند.
        </p>
      </div>
    </div>
  );
}
