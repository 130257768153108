import { lazy, Suspense, useCallback, useEffect, useMemo } from "react";
import {
  socketClient,
  SocketConnectionState,
  useWindowDimensions,
  FontAwesomeSvgIcon,
  SuspenseLoading,
  Timer,
  EnvironmentVariables,
} from "../../../core/core";
import { faCommentDots } from "@fortawesome/free-regular-svg-icons";
import { Button } from "@heroui/react";
import { useProfileModal } from "../../profile/view/Profile";
import { useNavigate, useParams } from "react-router-dom";
import { usePlansModal } from "../../payment/plans/view/usePlansModal";
import { usePromoCodeModal } from "../../promo-code/view/usePromoCodeModal";
import { usePaymentHistoryModal } from "../../payment/history/view/usePaymentHistoryModal";
import { AppBar } from "./components/AppBar";
import { useAppDrawer } from "./components/useAppDrawer";
import { SocketReconnectionIndicator } from "./components/SocketReconnectionIndicator";
import { useReferralModal } from "../../referral/view/useReferralModal";
import {
  useNoSparkModal,
  useMatchFoundModal,
  useMatchMaking,
  useCancelMatchMakingConfirmModal,
  useMatchMakingModal,
  useMatchMakingTimeoutModal,
} from "../../match_making/match-making";
import { useSupportModal } from "../../support/view/useSupportModal";
import { useInitFeedback } from "../../feedback/view/useInitFeedback";
import { PreLaunchDescription } from "./components/PreLaunchDescription";
import {
  AnalyticsEvents,
  logAnalyticsEvent,
  useFcmInit,
  useInitNotificationsClickListener,
} from "../../firebase/firebase";
import { useBatteryModal } from "../../battery/view/useBatteryModal";
import { toast } from "react-toastify";

const Chats = lazy(() => import("../../chat/view/Chats/Chats"));
const Chat = lazy(() =>
  import("../../chat/view/Chat/Chat").then((module) => ({
    default: module.Chat,
  }))
);
const MatchMaking = lazy(() => import("../../match_making/view/MatchMaking"));

export default function App() {
  const navigate = useNavigate();
  const roomId = useParams().roomId ?? null;

  useInitNotificationsClickListener();

  const { pushNotificationPermissionModal } = useFcmInit();
  const { feedbackModal } = useInitFeedback();
  const { openProfileModal, profileModal } = useProfileModal();
  const { openPlansModal, plansModal } = usePlansModal();
  const { openBatteryModal, batteryModal } = useBatteryModal();
  const { openPromoCodeModal, promoCodeModal } = usePromoCodeModal();
  const { openReferralModal, referralModal } = useReferralModal();
  const { openPaymentHistoryModal, paymentHistoryModal } =
    usePaymentHistoryModal();
  const { supportModal, openSupportModal } = useSupportModal();
  const { openDrawer, drawerModal, logOutModal } = useAppDrawer({
    openProfileClicked: openProfileModal,
    openMyBatteryClicked: openBatteryModal,
    onPromoCodeClicked: openPromoCodeModal,
    onReferralClicked: openReferralModal,
    onPaymentHistoryClicked: openPaymentHistoryModal,
    onBuySparkClicked: openPlansModal,
    onSupportClicked: openSupportModal,
  });

  const isRoomOpen = roomId !== null;
  const onCloseChatPressed = useCallback(() => navigate("/"), [navigate]);
  const onRoomSelected = useCallback(
    (roomId: string) => {
      if (EnvironmentVariables.isPreLaunch) {
        return;
      }
      navigate(`/${roomId}`);
    },
    [navigate]
  );

  const { width } = useWindowDimensions();
  const isMobile = useMemo(() => width < 768, [width]);

  const { noSparkModal, openNoSparkModal } = useNoSparkModal();
  const { matchFoundModal, openMatchFoundModal } = useMatchFoundModal();
  const { matchMakingTimeoutModal, openMatchMakingTimeoutModal } =
    useMatchMakingTimeoutModal();
  const {
    matchMakingState,
    startMatchMakingState,
    startMatchmaking,
    onCancelSuccess,
  } = useMatchMaking({
    onInsufficientSpark: openNoSparkModal,
    onMatchFound: openMatchFoundModal,
    onTimeoutEnded: openMatchMakingTimeoutModal,
    onCoolDownNotFinished: (endDuration) =>
      toast.error(
        <p>یکم نفس بگیر! {endDuration} ثانیه دیگه می‌تونی دوباره تلاش کنی.</p>
      ),
  });
  const {
    cancelConfirmModal,
    openCancelConfirmModal,
    closeCancelConfirmModal,
  } = useCancelMatchMakingConfirmModal(onCancelSuccess);
  const { matchMakingModal, openMatchMakingModal } = useMatchMakingModal({
    closeCancelConfirmModal,
    matchMakingState,
    openCancelConfirmModal,
    startMatchmaking,
    startMatchMakingState,
  });

  useEffect(() => {
    if (
      !EnvironmentVariables.isPreLaunch &&
      socketClient.connectionState === SocketConnectionState.disconnected
    ) {
      socketClient.startSocket();
    }
  }, []);

  return (
    <>
      <div className="flex flex-row" style={{ height: window.innerHeight }}>
        <div
          className={
            "w-1/4 min-w-80 flex flex-col flex-grow border-l-1 relative overflow-clip" +
            (isMobile && isRoomOpen ? " hidden" : "")
          }
        >
          <AppBar
            onMenuClicked={openDrawer}
            onBatteryClicked={openBatteryModal}
            isRoomOpenOnMobile={isRoomOpen && isMobile}
          />
          {EnvironmentVariables.isPreLaunch ? (
            <>
              {isMobile ? (
                <PreLaunchDescription />
              ) : (
                <div>
                  <div className="absolute top-[61px] left-0 right-0 bottom-0 z-10 backdrop-blur-sm" />
                  <Suspense fallback={<SuspenseLoading />}>
                    <Chats
                      onRoomSelected={onRoomSelected}
                      selectedRoomId={roomId}
                    />
                  </Suspense>
                </div>
              )}
            </>
          ) : (
            <Suspense fallback={<SuspenseLoading />}>
              <Chats onRoomSelected={onRoomSelected} selectedRoomId={roomId} />
            </Suspense>
          )}
          <SocketReconnectionIndicator />
          {!EnvironmentVariables.isPreLaunch && (
            <span
              className="absolute right-4 transition-all duration-150"
              style={{
                bottom: isRoomOpen || isMobile ? "16px" : "-56px",
              }}
            >
              <Button
                variant="solid"
                color="primary"
                radius="full"
                startContent={<FontAwesomeSvgIcon icon={faCommentDots} />}
                size="lg"
                onPress={() => {
                  logAnalyticsEvent(AnalyticsEvents.home.newChatButtonClicked);
                  onCloseChatPressed();
                  openMatchMakingModal();
                }}
              >
                {matchMakingState.maybeFold({
                  loadSuccess: (state) =>
                    state.timeout ? (
                      <div className="w-10 flex-row justify-center">
                        <Timer
                          end={state.timeout}
                          onEnd={() => {
                            closeCancelConfirmModal();
                          }}
                        />
                      </div>
                    ) : null,
                  orElse: () => null,
                }) ?? "چت جدید"}
              </Button>
            </span>
          )}
        </div>
        {isMobile ? (
          isRoomOpen && (
            <div className="w-full min-w-0">
              <Suspense fallback={<SuspenseLoading />}>
                <Chat roomId={roomId} onCloseChatPressed={onCloseChatPressed} />
              </Suspense>
            </div>
          )
        ) : (
          <div className="w-3/4 min-w-0">
            {isRoomOpen ? (
              <Suspense fallback={<SuspenseLoading />}>
                <Chat roomId={roomId} onCloseChatPressed={onCloseChatPressed} />
              </Suspense>
            ) : (
              <Suspense fallback={<SuspenseLoading />}>
                {EnvironmentVariables.isPreLaunch ? (
                  <PreLaunchDescription />
                ) : (
                  <Suspense fallback={<SuspenseLoading />}>
                    <MatchMaking
                      matchMakingState={matchMakingState}
                      startMatchMakingState={startMatchMakingState}
                      startMatchmaking={startMatchmaking}
                      openCancelConfirmModal={openCancelConfirmModal}
                      closeCancelConfirmModal={closeCancelConfirmModal}
                    />
                  </Suspense>
                )}
              </Suspense>
            )}
          </div>
        )}
      </div>

      {pushNotificationPermissionModal}
      {feedbackModal}
      {drawerModal}
      {logOutModal}
      {profileModal}
      {!EnvironmentVariables.isPreLaunch && plansModal}
      {!EnvironmentVariables.isPreLaunch && batteryModal}
      {!EnvironmentVariables.isPreLaunch && promoCodeModal}
      {referralModal}
      {!EnvironmentVariables.isPreLaunch && paymentHistoryModal}
      {supportModal}

      {!EnvironmentVariables.isPreLaunch && noSparkModal}
      {!EnvironmentVariables.isPreLaunch && matchFoundModal}
      {!EnvironmentVariables.isPreLaunch && cancelConfirmModal}
      {!EnvironmentVariables.isPreLaunch && matchMakingTimeoutModal}
      {!EnvironmentVariables.isPreLaunch && isMobile && matchMakingModal}
    </>
  );
}
