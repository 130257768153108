import { DomainFailure, RemoteDataSource, Result } from "../../../core/core";
import { Wallet } from "./domain";

export class WalletRemoteDataSource extends RemoteDataSource {
  async getWallets(): Promise<Result<DomainFailure, Wallet[]>> {
    const result = await this.httpClient.get("/wallet");

    return result.map((data) => data as Wallet[]);
  }
}
