import {
  useDisclosure,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
} from "@heroui/react";
import { ReactNode } from "react";
import { useAsyncAction, showErrorToast } from "../../../../core/core";
import { matchmakingRepository } from "../../../../core/services";
import { AnalyticsEvents, logAnalyticsEvent } from "../../../firebase/firebase";

export function useCancelMatchMakingConfirmModal(onSuccess: () => void): {
  cancelConfirmModal: ReactNode;
  openCancelConfirmModal: () => void;
  closeCancelConfirmModal: () => void;
} {
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();

  const [submitState, submitAction] = useAsyncAction({
    action: () => matchmakingRepository.cancelMatchmaking(),
    onFailure: showErrorToast,
    onSuccess: () => {
      onClose();
      onSuccess();
    },
  });

  return {
    openCancelConfirmModal: onOpen,
    closeCancelConfirmModal: onClose,
    cancelConfirmModal: (
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        isDismissable={!submitState.isLoading}
        isKeyboardDismissDisabled={submitState.isLoading}
        hideCloseButton={true}
        size="sm"
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader className="flex flex-col gap-1 p-4">
                لغو جستجوی چت جدید؟
              </ModalHeader>
              <ModalBody className="px-4">
                <p>آیا میخواهید جستجوی چت جدید را لغو کنید؟</p>
              </ModalBody>
              <ModalFooter className="p-4">
                <Button
                  color="primary"
                  variant="bordered"
                  isDisabled={submitState.isLoading}
                  onPress={() => {
                    logAnalyticsEvent(
                      AnalyticsEvents.matchMaking
                        .cancelConfirmationDialogCancelButtonClicked
                    );
                    onClose();
                  }}
                  fullWidth
                >
                  انصراف
                </Button>
                <Button
                  color="danger"
                  variant="solid"
                  isLoading={submitState.isLoading}
                  onPress={() => {
                    logAnalyticsEvent(
                      AnalyticsEvents.matchMaking
                        .cancelConfirmationDialogConfirmButtonClicked
                    );
                    submitAction.invoke({});
                  }}
                  fullWidth
                >
                  تایید
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    ),
  };
}
