import {
  DomainFailure,
  nothing,
  Nothing,
  RemoteDataSource,
  Result,
} from "../../../core/core";
import Credentials from "./credentials";

export default class AuthRemoteDataSource extends RemoteDataSource {
  async register(phone: string): Promise<Result<DomainFailure, string>> {
    const result = await this.httpClient.post("/auth/register", {
      body: { phone },
    });

    return result.map((data) => data["token"] as string);
  }

  async verify(
    token: string,
    code: string,
    inviteCode: string | null
  ): Promise<Result<DomainFailure, Credentials>> {
    const result = await this.httpClient.post("/auth/verify", {
      headers: { Authorization: `Bearer ${token}` },
      body: { code, inviteCode },
    });

    return result.map((data) => data as Credentials);
  }

  async renewToken(
    refreshToken: string
  ): Promise<Result<DomainFailure, Credentials>> {
    const result = await this.httpClient.post("/auth/renew-token", {
      headers: { Authorization: `Bearer ${refreshToken}` },
    });

    return result.map((data) => data as Credentials);
  }

  async logOut(): Promise<Result<DomainFailure, Nothing>> {
    const result = await this.httpClient.post("/auth/logout");

    return result.map(() => nothing);
  }
}

// LATER: Telegram login
// function loginWithTelegram() {
//   const botId = "7072282430";
//
//   var popup_url =
//     `https://oauth.telegram.org/auth?bot_id=${encodeURIComponent(botId)}` +
//     `&origin=${encodeURIComponent(
//       location.origin || location.protocol + "//" + location.hostname
//     )}` +
//     `&request_access=${encodeURIComponent(
//       "write"
//     )},&lang=${encodeURIComponent("fa")}&return_to=${encodeURIComponent(
//       location.href
//     )}`;
//
//   const width = 550;
//   const height = 470;
//   const left =
//     Math.max(0, (screen.width - width) / 2) + (screen.availLeft | 0);
//   const top =
//     Math.max(0, (screen.height - height) / 2) + (screen.availTop | 0);
//   const popup = window.open(
//     popup_url,
//     "telegram_oauth_bot" + botId,
//     `width=${width},height=${height},left=${left},top=${top},status=0,location=0,menubar=0,toolbar=0`
//   );
//
//   if (popup) {
//     window.addEventListener(
//       "message",
//       (event) => {
//         if (event.source != popup) return;
//         const data = JSON.parse(event.data);
//         if (data.event == "auth_result") {
//           console.log(data.result);
//         }
//       },
//       false
//     );
//   }
// }
