import { Observable } from "rxjs";
import { DomainFailure, Nothing, Result } from "../../../core/core";
import {
  DeleteMessagesRequest,
  GetMessagesRequest,
  IsTypingRequest,
  SeenMessagesRequest,
  SendMessageRequest,
  UpdateMessageRequest,
  ChatMessage,
  ChatRoomSummary,
  DeleteMessages,
  IsTyping,
  SeenMessages,
  ChatRoom,
  ChatMessageWithRoomId,
  UserOnlineStatus,
} from "./entities/entities";
import { ChatSocketDataSource } from "./chat-socket-data-source";
import { ChatRemoteDataSource } from "./chat-remote-data-source";

export class ChatRepository {
  constructor(
    private readonly _remoteDataSource: ChatRemoteDataSource,
    private readonly _socketDataSource: ChatSocketDataSource
  ) {}

  getRooms(): Promise<Result<DomainFailure, ChatRoomSummary[]>> {
    return this._remoteDataSource.getRooms();
  }

  getRoom(roomId: string): Promise<Result<DomainFailure, ChatRoom>> {
    return this._remoteDataSource.getRoom(roomId);
  }

  getUnreadMessages(
    roomId: string
  ): Promise<Result<DomainFailure, ChatMessage[]>> {
    return this._remoteDataSource.getUnreadMessages(roomId);
  }

  getMessages(
    params: GetMessagesRequest
  ): Promise<Result<DomainFailure, ChatMessage[]>> {
    return this._remoteDataSource.getMessages(params);
  }

  getUserOnlineStatus(
    userId: string
  ): Promise<Result<DomainFailure, UserOnlineStatus>> {
    return this._remoteDataSource.getUserOnlineStatus(userId);
  }

  sendMessage(
    params: SendMessageRequest
  ): Promise<Result<DomainFailure, ChatMessage>> {
    return this._socketDataSource.sendMessage(params);
  }

  updateMessage(
    params: UpdateMessageRequest
  ): Promise<Result<DomainFailure, ChatMessage>> {
    return this._socketDataSource.updateMessage(params);
  }

  seenMessages(
    params: SeenMessagesRequest
  ): Promise<Result<DomainFailure, Nothing>> {
    return this._socketDataSource.seenMessages(params);
  }

  deleteMessages(
    params: DeleteMessagesRequest
  ): Promise<Result<DomainFailure, Nothing>> {
    return this._socketDataSource.deleteMessages(params);
  }

  sendIsTyping(
    params: IsTypingRequest
  ): Promise<Result<DomainFailure, Nothing>> {
    return this._socketDataSource.sendIsTyping(params);
  }

  watchNewMessage(): Observable<ChatMessageWithRoomId> {
    return this._socketDataSource.watchNewMessage();
  }

  watchUpdateMessage(): Observable<ChatMessageWithRoomId> {
    return this._socketDataSource.watchUpdateMessage();
  }

  watchSeenMessages(): Observable<SeenMessages> {
    return this._socketDataSource.watchSeenMessages();
  }

  watchDeleteMessages(): Observable<DeleteMessages> {
    return this._socketDataSource.watchDeleteMessages();
  }

  watchIsTyping(): Observable<IsTyping> {
    return this._socketDataSource.watchIsTyping();
  }

  watchReloadRooms(): Observable<Nothing> {
    return this._socketDataSource.watchReloadRooms();
  }
}
