import { LocalDataSource } from "../../../core/core";

export class MatchmakingLocaleDataSource extends LocalDataSource {
  private _lastSuccessMatchDate = 'last_success_match_date';

  setLastSuccessMatchDate(timestamp: Date): void {
    this.cacheManager.set(this._lastSuccessMatchDate, timestamp.toISOString());
  }

  getLastSuccessMatchDate(): Date | undefined {
    const data = this.cacheManager.get<string>(this._lastSuccessMatchDate);
    return data ? new Date(data) : undefined;
  }

  removeLastSuccessMatchDate(): void {
    this.cacheManager.remove(this._lastSuccessMatchDate);
  }
}
