import { Button, Input, Link } from "@heroui/react";
import { faArrowRightToBracket } from "@fortawesome/free-solid-svg-icons";
import AuthWrapper from "./components/AuthWrapper";
import { useCallback, useState } from "react";
import {
  authRepository,
  FontAwesomeSvgIcon,
  showErrorToast,
  useAsyncAction,
} from "../../../core/core";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { showRegisterError } from "./components/showRegisterError";
import { AnalyticsEvents, logAnalyticsEvent } from "../../firebase/firebase";

type PhoneState = {
  phone: string;
  isPhoneValid: boolean;
  showErrors: boolean;
};

function isPhoneValid(phone: string): boolean {
  const re = new RegExp(/^09\d{9}$/);
  return re.test(phone);
}

export default function Phone() {
  const navigate = useNavigate();
  const [state, setState] = useState<PhoneState>({
    phone: "",
    isPhoneValid: false,
    showErrors: false,
  });

  const onSuccess = useCallback(
    (token: string) => {
      navigate(
        `/auth/phone/verification/98${state.phone.substring(1)}/${token}` +
          location.search
      );
    },
    [navigate, state.phone]
  );

  const [submitState, submitAction] = useAsyncAction({
    action: (params: PhoneState) =>
      authRepository.register(`98${params.phone.substring(1)}`),
    onFailure: (failure) =>
      failure.fold({
        onNetworkFailure: () => showErrorToast(failure),
        onServerFailure: (failure) =>
          showRegisterError(failure.errorCode ?? ""),
      }),
    onSuccess: onSuccess,
  });

  const onSubmit = () => {
    logAnalyticsEvent(AnalyticsEvents.phone.submitButtonClicked);

    if (!isPhoneValid(state.phone)) {
      setState({ ...state, showErrors: true });
      return;
    }

    submitAction.invoke(state);
  };

  return (
    <AuthWrapper>
      <div className="w-full md:w-80 left mt-8">
        <Input
          style={{ direction: "ltr" }}
          isRequired
          autoFocus
          type="tel"
          inputMode="tel"
          label="شماره موبایل"
          classNames={{
            input: ["text-left"],
            inputWrapper: [
              "group-data-[focus-visible=true]:ring-0 group-data-[focus-visible=true]:ring-offset-0",
            ],
          }}
          value={state.phone}
          disabled={submitState.isLoading}
          isInvalid={state.showErrors && !state.isPhoneValid}
          errorMessage={"شماره موبایل معتبر نیست"}
          onChange={(event) => {
            const phone = event.target.value.trim();

            const re = new RegExp(/^\d+$/);

            if (phone.length == 0 || (phone.length <= 11 && re.test(phone))) {
              setState({
                ...state,
                phone,
                isPhoneValid: isPhoneValid(phone),
              });
            }
          }}
          onKeyUp={(e) => {
            if (state.phone.length == 11 && e.key === "Enter") {
              onSubmit();
            }
          }}
        />
        <div className="h-2" />
        <Button
          color="primary"
          variant="solid"
          size="lg"
          fullWidth
          endContent={<FontAwesomeSvgIcon icon={faArrowRightToBracket} />}
          isDisabled={state.phone.length != 11}
          isLoading={submitState.isLoading}
          // With onPress it closes the keyboard first and user has to tap on the button again
          onPressStart={onSubmit}
        >
          ورود
        </Button>
        <p className="text-sm text-center py-3">
          با ادامه و وارد کردن شماره تلفن، شما با{" "}
          <Link
            className="text-sm"
            onPress={() =>
              logAnalyticsEvent(AnalyticsEvents.phone.termsButtonClicked)
            }
          >
            <RouterLink to="/terms">قوانین استفاده</RouterLink>
          </Link>
          {" و "}
          <Link
            className="text-sm"
            onPress={() =>
              logAnalyticsEvent(AnalyticsEvents.phone.privacyButtonClicked)
            }
          >
            <RouterLink to="/privacy">حریم خصوصی</RouterLink>
          </Link>{" "}
          فندق موافقت می‌کنید.
        </p>
      </div>
    </AuthWrapper>
  );
}
