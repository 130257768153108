import { BehaviorSubject } from "rxjs";
import { Result } from "../../domain/domain";

class AsyncAction<F, S> {
  constructor(readonly isLoading: boolean, readonly result?: Result<F, S>) {}

  static initial<F, S>() {
    return new AsyncAction<F, S>(false);
  }

  static loading<F, S>() {
    return new AsyncAction<F, S>(true);
  }

  static result<F, S>(result: Result<F, S>) {
    return new AsyncAction<F, S>(false, result);
  }
}

class AsyncActionController<F, S> {
  private _state: BehaviorSubject<AsyncAction<F, S>>;

  public get state() {
    return this._state;
  }

  constructor(
    private readonly action: (params: unknown) => Promise<Result<F, S>>
  ) {
    this._state = new BehaviorSubject<AsyncAction<F, S>>(
      AsyncAction.initial<F, S>()
    );
  }

  public async invoke(params: unknown): Promise<void> {
    this._state.next(AsyncAction.loading<F, S>());

    const result = await this.action(params);

    this._state.next(AsyncAction.result<F, S>(result));
    setTimeout(() => {
      this._state.next(AsyncAction.initial<F, S>());
    }, 0);
  }
}

export { AsyncAction, AsyncActionController };
