import { useEffect, useState } from "react";
import { socketClient, SocketConnectionState } from "../../../core/core";

export function useSocketConnectionState() {
  const [connectionState, setConnectionState] = useState<SocketConnectionState>(
    SocketConnectionState.disconnected
  );

  useEffect(() => {
    const subscription =
      socketClient.connectionStateSubject.subscribe(setConnectionState);
    return () => subscription.unsubscribe();
  }, []);

  return connectionState;
}
