import {
  Link,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  useDisclosure,
} from "@heroui/react";
import { ReactNode } from "react";
import { Link as RouterLink } from "react-router-dom";

export function useBatteryGuideModal(): {
  batteryGuideModal: ReactNode;
  openBatteryGuideModal: () => void;
} {
  const { isOpen, onOpen, onOpenChange } = useDisclosure();

  return {
    openBatteryGuideModal: onOpen,
    batteryGuideModal: (
      <Modal
        isOpen={isOpen}
        onOpenChange={onOpenChange}
        hideCloseButton={true}
        size="md"
        backdrop="blur"
      >
        <ModalContent>
          {() => (
            <>
              <ModalHeader className="flex flex-col gap-1 p-4">
                راهنمای باتری
              </ModalHeader>
              <ModalBody className="px-4 pb-6">
                <h1 className="text-md font-medium">
                  ⚡ چطوری باتری رو شارژ کنم؟
                </h1>
                <ul className="list-disc list-inside pr-2 space-y-1 text-sm">
                  <li>هر ۲۴ ساعت یک بار باتری روی میتونی رایگان شارژ کنی.</li>
                  <li>
                    اما اگه استار داشته باشی، می‌تونی هر موقع که خواستی بدون
                    انتظار باتریتو پر کنی!
                  </li>
                </ul>

                <h1 className="text-md font-medium mt-2">
                  ⭐ چطوری استار بگیرم؟
                </h1>
                <ul className="list-disc list-inside pr-2 space-y-1 text-sm">
                  <li>
                    با{" "}
                    <Link>
                      <RouterLink to={`${location.pathname}#invite`}>
                        دعوت از دوستات
                      </RouterLink>
                    </Link>
                    ، استار می‌گیری!
                  </li>
                  <li>
                    هر <span className="font-bold">۳ نفر</span> که با لینک دعوتت
                    بیان، <span className="font-bold">یک استار</span> به دست
                    میاری.
                  </li>
                </ul>

                <h1 className="text-md font-medium mt-2">
                  🔥 محاسبه مصرف باتری
                </h1>
                <ul className="list-disc list-inside pr-2 space-y-1 text-sm">
                  <li>اگه جنسیت انتخاب کنی، ۲ واحد کم می‌شه.</li>
                  <li>اگه شانسی وصل بشی، ۱ واحد کم می‌شه.</li>
                </ul>
              </ModalBody>
            </>
          )}
        </ModalContent>
      </Modal>
    ),
  };
}
