import { Button, Spacer } from "@heroui/react";
import {
  faArrowRightToBracket,
  faLock,
  faShield,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { faComment } from "@fortawesome/free-regular-svg-icons";
import AuthWrapper from "./components/AuthWrapper";
import { useNavigate } from "react-router-dom";
import { FontAwesomeSvgIcon } from "../../../core/core";
import { useCallback } from "react";
import { AnalyticsEvents, logAnalyticsEvent } from "../../firebase/firebase";

export default function Auth() {
  const navigate = useNavigate();

  const onJoinClicked = useCallback(() => {
    logAnalyticsEvent(AnalyticsEvents.join.joinButtonClicked);
    navigate("/auth/phone" + location.search);
  }, [navigate]);

  return (
    <AuthWrapper>
      <h3
        style={{
          fontSize: 16,
          marginBottom: 16,
          marginTop: 8,
          textAlign: "center",
        }}
      >
        با فندق به راحتی و با اطمینان کامل،
        <br />
        با دوستان جدید چت کنید.
      </h3>
      <div className="h-6" />
      <div>
        <Item title="چت ناشناس" icon={faComment} />
        <Spacer />
        <Item title="امنیت بالا" icon={faLock} />
        <Spacer />
        <Item title="حریم خصوصی کامل" icon={faShield} />
      </div>
      <div className="h-6" />
      <div className="w-full md:w-1/2">
        <Button
          color="primary"
          variant="solid"
          size="lg"
          fullWidth
          endContent={<FontAwesomeSvgIcon icon={faArrowRightToBracket} />}
          onPress={onJoinClicked}
        >
          ورود
        </Button>
      </div>
    </AuthWrapper>
  );
}

function Item(props: { title: string; icon: IconDefinition }) {
  return (
    <div className="flex flex-row items-center">
      <FontAwesomeSvgIcon
        icon={props.icon}
        fontSize={22}
        className="bg-blue-400 text-white p-3 rounded-full"
      />
      <Spacer />
      <h2 style={{ fontSize: 22, marginRight: 16 }}>{props.title}</h2>
    </div>
  );
}
